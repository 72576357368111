import type { SetOptional } from 'type-fest'
import type { BoardItem, BoardTier, BoardPlayer, BoardTeam, BoardDataItem } from '../definitions/dto/BoardData'
import { index, ascending, descending } from 'd3-array'

type BoardItemType = BoardDataItem['type']
interface BoardItemPosition {
    position: BoardItem['position']
}
interface BoardItemWithType extends BoardItemPosition {
    type: BoardItemType
}

function defaultSort<T extends BoardItemWithType>(a: T, b: T): number {
    return ascending(a.position, b.position) || descending(a.type === 'tier', b.type === 'tier')
}

export function getSortedBoardItems<T extends BoardItemWithType>(items: T[]): T[]
export function getSortedBoardItems<T extends BoardItemWithType>(items: T[], sort: typeof defaultSort<T>): T[]
export function getSortedBoardItems<T extends BoardItemPosition, U extends BoardItemPosition>(
    tiers: T[],
    playersOrTeams: U[]
): (T | U)[]
export function getSortedBoardItems<T extends BoardItemWithType | BoardItemPosition, U extends BoardItemPosition>(
    itemsOrTiers: T[],
    playersOrTeamsOrSort?: U[] | typeof defaultSort
): (T | U)[] | T[] {
    const sortBoardItems = typeof playersOrTeamsOrSort === 'function' ? playersOrTeamsOrSort : defaultSort
    // function received a singular array of items
    if (!Array.isArray(playersOrTeamsOrSort))
        return [...itemsOrTiers].sort((a, b) => sortBoardItems(a as BoardItemWithType, b as BoardItemWithType))
    // function received two arrays
    // assign a fake "type" to the object to sort it correctly, then remove the added property
    return [
        ...itemsOrTiers.map<BoardItemWithType>((t) => ({ ...t, type: 'tier' })),
        ...playersOrTeamsOrSort.map<BoardItemWithType>((t) => ({ ...t, type: 'player' })),
    ]
        .sort(sortBoardItems)
        .map(({ type, ...i }) => i as T | U)
}

interface BoardItemName extends BoardItemPosition {
    name: BoardTier['name']
}
interface BoardItemNameWithType extends BoardItemWithType, BoardItemPosition {
    name: BoardTier['name']
}
export type TierNameMap<T> = Map<string | null, T[]>
export type ItemPositionTierMap = Map<number, string | null>

export function mapItemsToTierNames<T extends BoardItemNameWithType>(
    items: T[],
    isPreSorted?: boolean
): [TierNameMap<T>, ItemPositionTierMap]
export function mapItemsToTierNames<T extends BoardItemName, U extends BoardItemPosition>(
    tiers: T[],
    playersOrTeams: U[]
): [TierNameMap<U>, ItemPositionTierMap]
export function mapItemsToTierNames<T extends BoardItemNameWithType | BoardItemName, U extends BoardItemPosition>(
    itemsOrTiers: T[],
    playersOrTeamsOrIsPreSorted: U[] | boolean = true
): [TierNameMap<T> | TierNameMap<U>, ItemPositionTierMap] {
    let sortedItems: (T | U)[]
    let tiers: T[]
    if (typeof playersOrTeamsOrIsPreSorted === 'boolean') {
        sortedItems = playersOrTeamsOrIsPreSorted
            ? itemsOrTiers
            : (getSortedBoardItems(itemsOrTiers as BoardItemNameWithType[]) as T[])
        tiers = sortedItems.filter((i) => (i as BoardItemNameWithType).type === 'tier') as T[]
    } else {
        sortedItems = getSortedBoardItems(itemsOrTiers, playersOrTeamsOrIsPreSorted)
        tiers = itemsOrTiers
    }

    const tierNameMap = new Map<string | null, (T | U)[]>()
    const itemTierMap = new Map<number, string | null>()
    const tierLookup = index(
        tiers,
        (t) => t.position,
        (t) => t.name
    )

    // create NULL entry for any items not in a tier
    let currentTierName: string | null = null
    let currentItemArray: (T | U)[] = []
    tierNameMap.set(currentTierName, currentItemArray)

    // loop through all sorted items
    sortedItems.forEach((i) => {
        const isTier = 'name' in i && !!tierLookup.get(i.position)?.has(i.name)
        if (isTier) {
            currentTierName = i.name
            currentItemArray = []
            tierNameMap.set(currentTierName, currentItemArray)
        } else {
            currentItemArray.push(i)
            itemTierMap.set(i.position, currentTierName)
        }
    })

    // remove the NULL entry if it is empty
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!tierNameMap.get(null)!.length) tierNameMap.delete(null)

    return [tierNameMap as TierNameMap<T> | TierNameMap<U>, itemTierMap]
}

export const recalculatePositionsAndRanks = <T extends BoardItemWithType>(
    items: T[],
    sortBy: typeof defaultSort<T> = defaultSort
): T[] => {
    let rank = 0

    return getSortedBoardItems(items, sortBy).map((i, position) => {
        if (i.type !== 'tier') {
            rank += 1
            return { ...i, position, rank }
        }
        return { ...i, position }
    })
}

export type MoveBoardItem =
    | Pick<BoardTier, 'type' | 'name' | 'position'>
    | SetOptional<Pick<BoardPlayer | BoardTeam, 'type' | 'id' | 'position' | 'isRanked' | 'entityId'>, 'isRanked'>
export const moveItemAndRecalculatePositionsAndRanks = <T extends MoveBoardItem>(
    movedItem: MoveBoardItem,
    items: T[]
): T[] => {
    // create reusable function to determine if the board item is the item being moved
    const isMatchingItem: (i: T) => boolean =
        movedItem.type === 'tier'
            ? (i) => i.type === 'tier' && i.name === movedItem.name
            : (i) => i.type !== 'tier' && i.id === movedItem.id

    // create custom sort so that if there is a position conflict, the newly moved item will take precedence
    const boardItemSortPosOverride = (a: T, b: T) =>
        ascending(a.position, b.position) ||
        descending(isMatchingItem(a), isMatchingItem(b)) ||
        descending(b.type, a.type)

    return recalculatePositionsAndRanks(
        items.map((i) => (isMatchingItem(i) ? { ...i, position: movedItem.position, isRanked: true } : i)),
        boardItemSortPosOverride
    )
}

export const duplicateItemTierErrorMessage = (item: Enum.BoardEntityType) =>
    `${item === 'PLAYER' ? 'Player' : 'Team'} already in tier`
