import type { UseQueryOptions, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const getPlayerWorkoutTracker = async (playerId: string, season: string): Promise<DTO.PlayerWorkoutTracker[]> =>
    (await get<DTO.PlayerWorkoutTracker[]>(`/player-workout-tracker/${playerId}/${season}`)).data

export const useGetPlayerWorkoutTracker = (
    playerId: string | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.PlayerWorkoutTracker[], Error, DTO.PlayerWorkoutTracker[], QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerWorkoutTracker[]> => {
    const { data: session } = useSession()
    return useQuery<DTO.PlayerWorkoutTracker[], Error, DTO.PlayerWorkoutTracker[], QueryKey>(
        ['playerWorkoutTracker', playerId, season],
        // @ts-expect-error playerId will only get referenced here when it is defined (enabled)
        () => getPlayerWorkoutTracker(playerId, season),
        {
            ...options,
            enabled:
                !!playerId &&
                !!season &&
                !!session?.roles.contentPermissions['player-draft-info'] &&
                options?.enabled !== false,
        }
    )
}
