'use client'

import React, { useCallback, useMemo, useState } from 'react'

export interface Toast {
    severity: 'success' | 'info' | 'warning' | 'error'
    message: string
    duration?: number
}

export interface ToastContextInterface {
    toast: Toast | null
    addToast: (newToast: Toast) => void
    removeToast: () => void
}

export const ToastContext = React.createContext<ToastContextInterface | null>(null)

export const ToastContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [toast, setToast] = useState<Toast | null>(null)

    const addToast = useCallback((newToast: Toast) => setToast(newToast), [])

    const removeToast = useCallback(() => setToast(null), [])

    const contextValue = useMemo(
        () => ({
            toast,
            addToast,
            removeToast,
        }),
        [toast, addToast, removeToast]
    )

    return <ToastContext.Provider value={contextValue}>{children}</ToastContext.Provider>
}
