import type { UseQueryResult, UseQueryOptions, UseMutationResult, QueryKey } from '@tanstack/react-query'
import type { JSONResponse } from '@/lib/api'
import { ascending, group, index, max, type InternMap } from 'd3-array'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useMemo } from 'react'
import { get, post, put, remove } from '../api'
import { useSubscribeToDraftEntrantChanges, useSubscribeToPickOrderChanges } from '../contexts/DraftApiContext'
import { useGetTeamPicks } from './useDraftAssets'
import { useGetKnicksOrgTeam } from './useTeam'
import useToastContext from './useToastContext'
import { useDepthChartList } from './useDepthCharts'

export type DraftPick = DTO.ChangePickOrder & {
    teamColor: string | null
    teamAbbr: string | null
    fullName: string
    position: string | null
    picked: boolean
    playerId: string | null
}

const queryDraftEligiblePlayers = async (league: Enum.DraftLeague, draft: number): Promise<DTO.DraftEligiblePlayer[]> =>
    (await get<DTO.DraftEligiblePlayer[]>(`/draft/draft-eligible-players/${league}/${draft}`)).data

const useQueryDraftEligiblePlayers = (
    draft: number,
    leagueName: Enum.DraftLeague,
    options?: Omit<
        UseQueryOptions<DTO.DraftEligiblePlayer[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.DraftEligiblePlayer[]> =>
    useQuery<DTO.DraftEligiblePlayer[]>(
        ['draft-night', 'draft-eligible-players', leagueName, draft],
        () => queryDraftEligiblePlayers(leagueName, draft),
        {
            ...options,
            enabled: !!draft,
        }
    )

export const mapDraftEligiblePlayerToDraftEntrant = (p: DTO.DraftEligiblePlayer): DTO.DraftEntrant => ({
    rank: p.rank,
    hasRank: p.hasRank,
    fullName: p.fullName,
    lastName: p.lastName,
    urlSlug: p.urlSlug,
    playerId: p.playerId,
    ageOnDraftDay: p.ageOnDraftDay,
    collegeClass: p.collegeClass,
    teamId: p.teamId,
    teamName: p.teamName,
    teamAbbr: p.teamAbbr,
    teamUrlSlug: p.teamUrlSlug,
    teamLogo: p.teamLogo,
    position: p.position,
    available: true,
    pick: null,
    twoWay: p.twoWay,
    summerLeague: p.summerLeague,
    draftAndStashGLG: p.draftAndStashGLG,
    draftAndStashIntl: p.draftAndStashIntl,
    draftOptionNotes: p.draftOptionNotes,
    greenRoomInvite: p.greenRoomInvite,
    contractType: null,
    salaryAmount: null,
    contractYears: null,
    signingTeamId: null,
    signingTeamLogo: null,
    signingTeamAbbr: null,
})

export const usePickOrder = (
    season: number,
    leagueName: Enum.DraftLeague,
    subscribeToChanges = true
): {
    pickOrder: DTO.ChangePickOrder[]
    teamLookup: InternMap<string, DTO.TeamPicks>
    pickOrderOverrideLookup: InternMap<number, DTO.ChangePickOrder>
    isLoading: boolean
} => {
    const { data: draftPicks, isLoading } = useGetTeamPicks(season, null, leagueName)
    const teamLookup = useMemo(() => index(draftPicks || [], (d) => d.teamId), [draftPicks])
    const pickOrderOverride = useSubscribeToPickOrderChanges(season, leagueName, subscribeToChanges)
    const pickOrderOverrideLookup = useMemo(() => index(pickOrderOverride || [], (d) => d.pick), [pickOrderOverride])
    const pickOrder: DTO.ChangePickOrder[] = useMemo(() => {
        if (!draftPicks) return []
        return draftPicks
            .flatMap((team) =>
                team.picks.map((pick) => {
                    const override = pickOrderOverrideLookup.get(pick.pick)
                    return {
                        pick: pick.pick,
                        teamId: team.teamId,
                        teamLogo: team.teamLogoURL || '',
                        teamName: team.teamName,
                        draft: season,
                        traded: !!override && override.teamId !== team.teamId,
                        ...override,
                    }
                })
            )
            .sort((a, b) => a.pick - b.pick)
    }, [draftPicks, season, pickOrderOverrideLookup])
    return { pickOrder, pickOrderOverrideLookup, teamLookup, isLoading }
}

export const useDraftEntrants = (
    season: number,
    leagueName: Enum.DraftLeague
): {
    draftEntrantsOverride: DTO.DraftEntrant[] | null | undefined
    draftEntrants: DTO.DraftEntrant[]
    draftEntrantsOverrideLookup: InternMap<string | null, DTO.DraftEntrant>
    draftEligiblePlayerLookup: InternMap<string | null, DTO.DraftEligiblePlayer>
    currentPick: number
    selectedPicks: number[]
    draftEligiblePlayers: DTO.DraftEligiblePlayer[]
    isLoading: boolean
    draftYear: number
    knicksPicks: { [key: number]: { pick: number; assignedRank: number } } | undefined
    maxRank: number | undefined
    draftPicks: DraftPick[]
} => {
    const { data: allDraftEntrants, isLoading } = useQueryDraftEligiblePlayers(season, leagueName)
    const { teamLookup, pickOrder } = usePickOrder(season, leagueName)
    const { data: nyk } = useGetKnicksOrgTeam(leagueName)
    const draftEntrantsOverride = useSubscribeToDraftEntrantChanges(season, leagueName)
    const draftEntrantsOverrideLookup = useMemo(
        () => index(draftEntrantsOverride || [], (d) => d.playerId),
        [draftEntrantsOverride]
    )
    const draftPicks: DraftPick[] = useMemo(() => {
        const draftEntrantsByPick = index(draftEntrantsOverride?.filter((d) => Boolean(d.pick)) || [], (d) => d.pick)
        return pickOrder.map((p) => {
            const player = draftEntrantsByPick.get(p.pick)
            const team = teamLookup.get(p.teamId)
            return {
                ...p,
                fullName: player?.fullName || '',
                teamColor: team?.teamColor || null,
                teamAbbr: team?.teamAbbr || null,
                position: player?.position || null,
                picked: Boolean(player),
                playerId: player?.playerId || null,
            }
        })
    }, [pickOrder, draftEntrantsOverride, teamLookup])
    const draftPicksByTeam = group(draftPicks, (d) => d.teamId)
    const nykPicks = nyk
        ? draftPicksByTeam
              .get(nyk.teamId)
              ?.filter((d) => !d.picked && d.pick)
              .map((p) => p.pick)
        : []
    const [draftEntrants, draftEligiblePlayers]: [DTO.DraftEntrant[], DTO.DraftEligiblePlayer[]] = useMemo(() => {
        if (!allDraftEntrants) return [[], []]
        const mappedPlayers: DTO.DraftEntrant[] = []
        const draftEligiblePlayersMemo: DTO.DraftEligiblePlayer[] = []
        const draftEligiblePlayersInitPlayerIds = new Set(allDraftEntrants.map((p) => p.playerId))

        allDraftEntrants.forEach((p) => {
            // do not override player IDs and rank of an existing player
            const { playerId, urlSlug, rank, ...override } = draftEntrantsOverrideLookup.get(p.playerId) || {}

            mappedPlayers.push({
                ...mapDraftEligiblePlayerToDraftEntrant(p),
                ...override,
            })

            draftEligiblePlayersMemo.push({
                ...p,
                ...override,
            })
        })

        // Add row where the playerId is not in allDraftEntrants (manual input use case)
        draftEntrantsOverrideLookup.forEach((override, playerId) => {
            if (!draftEligiblePlayersInitPlayerIds.has(playerId)) {
                mappedPlayers.push(override)
            }
        })

        return [mappedPlayers, draftEligiblePlayersMemo]
    }, [allDraftEntrants, draftEntrantsOverrideLookup])
    const draftEligiblePlayerLookup = index(draftEligiblePlayers, (d) => d.playerId)
    const selectedPicks = draftEntrantsOverride?.filter((d) => Boolean(d.pick)).map((p) => p.pick || 0) || []
    const currentPick = selectedPicks.length ? Number(max(selectedPicks.map((p) => p))) + 1 : 1
    const sortedAvailableBigBoard = [...draftEligiblePlayers]
        .sort((a, b) => ascending(a.rank || 999, b.rank || 999))
        .filter((b) => b.available && b.hasRank)
    const knicksPicks = nykPicks?.reduce((acc: Record<number, { pick: number; assignedRank: number }>, d) => {
        const picksFromNow = d - (selectedPicks.length || 0)
        const assignedRank =
            sortedAvailableBigBoard.length >= picksFromNow - 1 ? sortedAvailableBigBoard[picksFromNow - 1] : undefined
        if (assignedRank?.rank) {
            acc[assignedRank.rank] = { pick: d, assignedRank: assignedRank.rank }
        }
        return acc
    }, {})

    const maxRank = max(draftEntrants.map((d) => d.rank || 0))
    return {
        draftEntrantsOverride,
        draftEntrantsOverrideLookup,
        draftEligiblePlayerLookup,
        draftEntrants,
        currentPick,
        selectedPicks,
        draftEligiblePlayers,
        isLoading,
        draftYear: season,
        knicksPicks,
        maxRank,
        draftPicks,
    }
}

export const useSaveDraftOptions = (): UseMutationResult<JSONResponse, Error, DTO.DraftOptions> =>
    useMutation((draftOptions: DTO.DraftOptions) =>
        post<undefined, DTO.DraftOptions>(`/draft/draft-options`, draftOptions)
    )

export const useSavePlayerSigning = (): UseMutationResult<JSONResponse, Error, DTO.DraftNightPlayerSigning> =>
    useMutation((signing: DTO.DraftNightPlayerSigning) =>
        post<undefined, DTO.DraftNightPlayerSigning>(
            `/draft/player-signings/${signing.season}/${signing.playerId}`,
            signing
        )
    )

type DeletePlayerSigning = Pick<DTO.DraftNightPlayerSigning, 'season' | 'playerId'>
export const useDeletePlayerSigning = (): UseMutationResult<JSONResponse, Error, DeletePlayerSigning> =>
    useMutation((signing: DeletePlayerSigning) =>
        remove(`/draft/player-signings/${signing.season}/${signing.playerId}`)
    )

const queryTeamNeeds = async (depthChartId: string | undefined): Promise<DTO.DepthChartItemModel[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.DepthChartItemModel[]>(`/draft/depth-chart-needs/${depthChartId}`)).data

export const useQueryTeamNeeds = (
    leagueName: Enum.DraftLeague,
    draftYear?: number,
    options?: Omit<
        UseQueryOptions<DTO.DepthChartItemModel[], Error, DTO.DepthChartItemModel[], QueryKey>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.DepthChartItemModel[]> => {
    const { data: depthCharts } = useDepthChartList(true)
    const designation: Enum.DepthChartDesignation = leagueName === 'NBA' ? 'NBA PTNDC' : 'GLG PTNDC'
    const depthChartId = depthCharts?.find(
        (dc) => dc.designation === designation && (draftYear ? dc.season === draftYear : dc.status === 'ACTIVE')
    )?.depthChartId
    return useQuery<DTO.DepthChartItemModel[], Error, DTO.DepthChartItemModel[], QueryKey>(
        ['depth-charts', depthChartId?.toLowerCase(), 'team-needs'],
        () => queryTeamNeeds(depthChartId),
        { ...options, refetchInterval: 60000, enabled: !!depthChartId && options?.enabled !== false }
    )
}

export const useSaveDraftNotes = (
    playerId: string,
    draft: number
): UseMutationResult<JSONResponse<string>, Error, { notes: string }> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (text: { notes: string }) => put<string, { notes: string }>(`/draft/player-notes/${draft}/${playerId}`, text),
        {
            onSuccess: async () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Saved Note',
                    duration: 2000,
                })
                await queryClient.invalidateQueries(['draft-night', 'big-board', draft])
            },
            onError: (error) => toastContext?.addToast({ severity: 'error', message: error.message }),
        }
    )
}

export const useSaveDraftTeamNotes = (
    draft: number,
    teamId: string,
    league: Enum.DraftLeague
): UseMutationResult<
    JSONResponse<string>,
    Error,
    { notes: string; noteId?: string; readAt?: string | null; message?: string; type: DTO.DraftTeamNote['type'] }
> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (values: {
            notes: string
            noteId?: string
            readAt?: string | null
            message?: string
            type: DTO.DraftTeamNote['type']
        }) =>
            post<
                string,
                {
                    notes: string
                    noteId?: string
                    readAt?: string | null
                    message?: string
                    type: DTO.DraftTeamNote['type']
                }
            >(`/draft/team-notes/${draft}/${teamId}`, values),
        {
            onMutate: async (newNote: {
                notes: string
                noteId?: string
                readAt?: string | null
                message?: string
                type: 'ACTIVE' | 'ARCHIVED'
            }) => {
                await queryClient.cancelQueries(['draft-night', 'team-center', league, draft])
                const previousTeamCenter = queryClient.getQueryData(['draft-night', 'team-center', league, draft])
                queryClient.setQueryData(
                    ['draft-night', 'team-center', league, draft],
                    (old: DTO.TeamCenter[] | undefined) =>
                        old?.map((tc) =>
                            tc.teamId === teamId
                                ? {
                                      ...tc,
                                      draftTeamNotes: tc.draftTeamNotes.map((dtn) => {
                                          if (dtn.noteId === newNote.noteId) {
                                              return {
                                                  ...dtn,
                                                  notes: newNote.notes,
                                                  noteId: newNote.noteId,
                                                  teamId,
                                                  draft,
                                                  updatedBy: 0,
                                                  updatedAt: new Date().toISOString(),
                                                  readAt: newNote.readAt || null,
                                                  type: newNote.type,
                                              }
                                          }
                                          return dtn
                                      }),
                                  }
                                : tc
                        )
                )

                return { previousTeamCenter }
            },
            onSuccess: async (
                _,
                newNote: {
                    notes: string
                    noteId?: string
                    readAt?: string | null
                    message?: string
                }
            ) => {
                toastContext?.addToast({
                    severity: 'success',
                    message: newNote.message || 'Saved Note',
                    duration: 2000,
                })
                await queryClient.invalidateQueries(['draft-night', 'team-center', league, draft])
            },
            onError: (error) => toastContext?.addToast({ severity: 'error', message: error.message }),
        }
    )
}
export const useSaveDraftPickNotes = (
    draft: number,
    teamId: string,
    pick: number
): UseMutationResult<
    JSONResponse<string>,
    Error,
    { notes: string; noteId?: string; readAt?: string | null; message?: string }
> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (values: { notes: string; noteId?: string; readAt?: string | null; message?: string }) =>
            post<string, { notes: string }>(`/draft/pick-notes/${draft}/${teamId}/${pick}`, values),
        {
            onMutate: async (newNote: { notes: string; noteId?: string; readAt?: string | null; message?: string }) => {
                await queryClient.cancelQueries(['draft-night', 'draft-pick-notes', draft])
                const previousDraftPickNotes = queryClient.getQueryData(['draft-night', 'draft-pick-notes', draft])
                queryClient.setQueryData(
                    ['draft-night', 'draft-pick-notes', draft],
                    (old: DTO.DraftPickNote[] | undefined) =>
                        old?.map((pc) =>
                            pc.noteId === newNote.noteId
                                ? {
                                      ...pc,
                                      notes: newNote.notes,
                                      noteId: newNote.noteId,
                                      teamId,
                                      pick,
                                      draft,
                                      updatedBy: 0,
                                      updatedAt: new Date().toISOString(),
                                      readAt: newNote.readAt || null,
                                  }
                                : pc
                        )
                )

                return { previousDraftPickNotes }
            },
            onSuccess: async (
                _,
                newNote: {
                    notes: string
                    noteId?: string
                    readAt?: string | null
                    message?: string
                }
            ) => {
                toastContext?.addToast({
                    severity: 'success',
                    message: newNote.message || 'Saved Note',
                    duration: 2000,
                })
                await queryClient.invalidateQueries(['draft-night', 'draft-pick-notes', draft])
            },
            onError: (error) => toastContext?.addToast({ severity: 'error', message: error.message }),
        }
    )
}

export const useQueryDraftPickNotes = (draft: number): UseQueryResult<DTO.DraftPickNote[]> =>
    useQuery(
        ['draft-night', 'draft-pick-notes', draft],
        async () => (await get<DTO.DraftPickNote[]>(`/draft/pick-notes/${draft}`)).data
    )

export const usePicksPerRound = (draft: number, league: Enum.DraftLeague): UseQueryResult<DTO.PicksPerRound> =>
    useQuery(
        ['draft-night', 'picks-per-round', draft, league],
        async () => (await get<DTO.PicksPerRound>(`/draft/num-picks/${draft}`, { league })).data
    )

const queryTeamCenter = async (league: Enum.DraftLeague, draft: number): Promise<DTO.TeamCenter[]> =>
    (await get<DTO.TeamCenter[]>(`/draft/team-center/${league}/${draft}`)).data

export const useTeamCenter = (
    league: Enum.DraftLeague,
    draft: number,
    options?: Omit<UseQueryOptions<DTO.TeamCenter[]>, 'queryKey' | 'queryFn' | 'useErrorBoundary'>
): UseQueryResult<DTO.TeamCenter[]> =>
    useQuery(['draft-night', 'team-center', league, draft], () => queryTeamCenter(league, draft), options)
