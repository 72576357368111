import type { JSONResponse } from '../api'
import type { UseQueryResult, UseMutationResult, QueryKey } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post, serverGet } from '../api'
import useToastContext from './useToastContext'

export const getStaffPersonalQueryKey = (urlSlug: string | undefined): QueryKey => ['staff-personal', urlSlug]

export const serverQueryStaffPersonal = async (
    urlSlug: string,
    session: Session | null
): Promise<DTO.StaffPersonal[]> => (await serverGet<DTO.StaffPersonal[]>(`staff-personal/${urlSlug}`, session)).data

export const queryStaffPersonal = async (urlSlug: string | undefined): Promise<DTO.StaffPersonal[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.StaffPersonal[]>(`staff-personal/${urlSlug}`)).data

export const useQueryStaffPersonal = (urlSlug: string | undefined): UseQueryResult<DTO.StaffPersonal[]> =>
    useQuery(getStaffPersonalQueryKey(urlSlug), () => queryStaffPersonal(urlSlug), {
        enabled: !!urlSlug,
    })

export const useCreateOrUpdateStaffPersonal = (
    urlSlug: string | undefined
): UseMutationResult<JSONResponse<DTO.StaffPersonal>, Error, DTO.CreateStaffPersonal> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (values: DTO.CreateStaffPersonal) =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            post<DTO.StaffPersonal, DTO.CreateStaffPersonal>(`staff-personal/${urlSlug}`, values),
        {
            onSuccess: async ({ data: staffPersonalOverride }) => {
                toastContext?.addToast({ severity: 'success', message: 'Saved Staff Info' })
                const queryKey = getStaffPersonalQueryKey(urlSlug)
                const prevData = queryClient.getQueryData<DTO.StaffPersonal[]>(queryKey)

                const defaultRow = prevData?.find((d) => !d.isOverride)
                if (defaultRow) {
                    queryClient.setQueryData(queryKey, [defaultRow, staffPersonalOverride])
                } else {
                    await queryClient.invalidateQueries(queryKey)
                }

                await queryClient.invalidateQueries(['entities', 'staff', urlSlug])
            },
            onError: (error) => {
                let { message } = error
                if ('response' in error) {
                    message = (error.response as JSONResponse<DTO.ValidationErrors>).data.errors
                        .map((e) => e.msg)
                        .join(' | ')
                }
                toastContext?.addToast({ severity: 'error', message })
            },
        }
    )
}
