import type { UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { get } from '../api'

const queryPlayerSummary = async (params: SummaryParams): Promise<DTO.PlayerSummary[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.PlayerSummary[]>(`/profile/${params.playerId}/summary`, { isGLeaguePlayer: params.isGLeaguePlayer }))
        .data

export type SummaryParams = {
    playerId: string | null | undefined
    isGLeaguePlayer: 'true' | 'false'
}
const usePlayerSummary = (
    params: SummaryParams,
    options?: Omit<
        UseQueryOptions<DTO.PlayerSummary[], Error, DTO.PlayerSummary[], QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerSummary[]> => {
    const { data: session } = useSession()
    return useQuery<DTO.PlayerSummary[], Error, DTO.PlayerSummary[], QueryKey>(
        ['player-summary', params.playerId],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        () => queryPlayerSummary(params),
        {
            ...options,
            enabled:
                !!session?.roles.featurePermissions['player-profile'] &&
                !!params.playerId &&
                options?.enabled !== false,
        }
    )
}

export default usePlayerSummary
