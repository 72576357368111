import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const usePlayerPositions = (
    playerId: string,
    season: number,
    options?: Pick<UseQueryOptions<DTO.PositionVersatilitySeason | null>, 'enabled'>
): UseQueryResult<DTO.SeasonPositions[]> =>
    useQuery(
        ['player-positions', playerId, season],
        async () => (await get<DTO.SeasonPositions[]>(`/profile/${playerId}/${season}/positions`)).data,
        {
            enabled: options?.enabled !== false,
        }
    )

export default usePlayerPositions
