import type { LiteralUnion } from 'type-fest'
import type { QueryKey, UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { ToastContextInterface } from '../contexts/ToastContext'
import type { Session } from 'next-auth'
import type { JSONResponse } from '../api'
import { useSession } from 'next-auth/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { moveItemAndRecalculatePositionsAndRanks } from '../../shared/utils/boards'
import { get, put, post, remove, serverGet } from '../api'
import { getBoardModalQueryKey } from './useBoards'

export const getBoardDataQueryKey = (boardId?: string): QueryKey =>
    boardId ? ['board-data', boardId.toLowerCase()] : ['board-data']

export const serverGetBoardData = async (boardId: string, session: Session | null): Promise<DTO.BoardData> =>
    (await serverGet<DTO.BoardData>(`/boards/${boardId}`, session)).data

export const getBoardData = async (boardId: string): Promise<DTO.BoardData> =>
    (await get<DTO.BoardData>(`/boards/${boardId}`)).data

export const useBoardData = (
    boardId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.BoardData>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.BoardData> =>
    // @ts-expect-error boardId will only get referenced here when it is defined (enabled)
    useQuery<DTO.BoardData>(getBoardDataQueryKey(boardId), () => getBoardData(boardId), {
        ...options,
        enabled: !!boardId && options?.enabled !== false,
    })

export type UpdateBoardType = DTO.CreateUpdateBoard | DTO.BoardData
const isCreateUpdateBoardType = (board: UpdateBoardType): board is DTO.CreateUpdateBoard => 'headline' in board
const getBoardQueryKey = (board: UpdateBoardType): QueryKey =>
    isCreateUpdateBoardType(board)
        ? getBoardModalQueryKey({ boardId: board.boardId })
        : getBoardDataQueryKey(board.boardId)

export type UpdateBoardParams = Pick<DTO.BoardData, 'boardId'> & { version: DTO.BoardData['version'] | undefined }
export const useUpdateBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<UpdateBoardType>, Error, UpdateBoardType> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedBoardData: UpdateBoardType) =>
            put<UpdateBoardType, UpdateBoardType>(`/boards/${boardId}`, updatedBoardData, undefined, {
                'If-Match': version,
            }),
        {
            onMutate: async (updatedBoardData: UpdateBoardType) => {
                const queryKey = getBoardQueryKey(updatedBoardData)
                await queryClient.cancelQueries(queryKey)
                const previousBoardData = queryClient.getQueryData(queryKey)
                queryClient.setQueryData(queryKey, updatedBoardData)
                return { previousBoardData, updatedBoardData }
            },
            onError: (err, updatedBoardData, context) => {
                const queryKey = getBoardQueryKey(updatedBoardData)
                queryClient.setQueryData(queryKey, context?.previousBoardData)
            },
            onSuccess: (data, updatedBoardData) => {
                const newVersion = data.headers.get('etag')
                if (typeof newVersion === 'string') {
                    const queryKey = getBoardQueryKey(updatedBoardData)
                    queryClient.setQueryData(queryKey, { ...updatedBoardData, version: newVersion })
                }
            },
            onSettled: async (data, error, updatedBoardData) => {
                if (isCreateUpdateBoardType(updatedBoardData)) {
                    await Promise.all([
                        queryClient.invalidateQueries(getBoardModalQueryKey()),
                        // only invalidate board player data when updating the board configuration/properties
                        queryClient.invalidateQueries(getBoardDataQueryKey(boardId)),
                    ])
                }
            },
        }
    )
}

type AddItemResp = { message?: string; warning?: string }
export const useAddPlayersToBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<AddItemResp>, Error, DTO.AddPlayersInput> => {
    const queryClient = useQueryClient()
    return useMutation(
        (boardPlayers: DTO.AddPlayersInput) =>
            post<AddItemResp, DTO.AddPlayersInput>(`/boards/${boardId}/players`, boardPlayers, undefined, {
                'If-Match': version,
            }),
        { onSettled: () => queryClient.invalidateQueries(getBoardDataQueryKey(boardId)) }
    )
}

export const useAddTeamsToBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<AddItemResp>, Error, DTO.AddTeamsInput> => {
    const queryClient = useQueryClient()
    return useMutation(
        (boardTeams: DTO.AddTeamsInput) =>
            post<AddItemResp, DTO.AddTeamsInput>(`/boards/${boardId}/teams`, boardTeams, undefined, {
                'If-Match': version,
            }),
        { onSettled: () => queryClient.invalidateQueries(getBoardDataQueryKey(boardId)) }
    )
}

export const useAddTierToBoard = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<DTO.AddTierInput>, Error, DTO.AddTierInput> => {
    const queryClient = useQueryClient()
    return useMutation(
        (boardTier: DTO.AddTierInput) =>
            post<DTO.AddTierInput, DTO.AddTierInput>(`/boards/${boardId}/tiers`, boardTier, undefined, {
                'If-Match': version,
            }),
        { onSettled: () => queryClient.invalidateQueries(getBoardDataQueryKey(boardId)) }
    )
}

export const useQueryBoardTypes = (): UseQueryResult<DTO.BoardType[]> =>
    useQuery<DTO.BoardType[]>(['boardTypes'], async () => (await get<DTO.BoardType[]>(`/boards/types`)).data)

export type EditTierInput = DTO.EditTierInput & Pick<DTO.BoardTier, 'id'>
export const useEditTierName = (
    { boardId, version }: UpdateBoardParams,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse<EditTierInput>, Error, EditTierInput> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedTier: EditTierInput) =>
            put<EditTierInput, EditTierInput>(
                `/boards/${boardId}/tiers/${updatedTier.position}`,
                updatedTier,
                undefined,
                {
                    'If-Match': version,
                }
            ),
        {
            onMutate: (updatedBoardTier: EditTierInput) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                    getBoardDataQueryKey(boardId)
                )
                if (previousBoardData) {
                    const updatedBoardData: DTO.BoardData = {
                        ...previousBoardData,
                        items: previousBoardData.items.map((i) => {
                            let { name } = i
                            if (i.type === 'tier' && i.id === updatedBoardTier.id) {
                                name = updatedBoardTier.name
                            }
                            return { ...i, name }
                        }),
                    }
                    queryClient.setQueryData(getBoardDataQueryKey(boardId), updatedBoardData)
                }
                return { previousBoardData }
            },
            onError: (err, updatedBoardTier, context) => {
                queryClient.setQueryData(getBoardDataQueryKey(boardId), context?.previousBoardData)
                toastContext?.addToast({
                    severity: 'error',
                    message: err.message,
                })
            },
            onSuccess: () => {
                void queryClient.invalidateQueries(getBoardDataQueryKey(boardId))
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Saved Changes',
                    duration: 1000,
                })
            },
        }
    )
}

export const useEditPlayerPosition = (
    boardId: string,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse, Error, DTO.InputPlayerPosition> => {
    const queryClient = useQueryClient()
    return useMutation(
        (inputPlayerPosition: DTO.InputPlayerPosition) =>
            put<unknown, DTO.InputPlayerPosition>(
                `/boards/${boardId}/players/${inputPlayerPosition.playerId}/position`,
                inputPlayerPosition
            ),
        {
            onMutate: (updatedPosition: DTO.InputPlayerPosition) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                    getBoardDataQueryKey(boardId)
                )
                if (previousBoardData) {
                    const updatedBoardData: DTO.BoardData = {
                        ...previousBoardData,
                        items: previousBoardData.items.map((i) => {
                            if (i.type === 'player' && i.id === updatedPosition.playerId) {
                                return { ...i, player: { ...i.player, playerPosition: updatedPosition.position } }
                            }
                            return i
                        }),
                    }
                    queryClient.setQueryData(getBoardDataQueryKey(boardId), updatedBoardData)
                }
                return { previousBoardData }
            },
            onError: (err, _, context) => {
                queryClient.setQueryData(getBoardDataQueryKey(boardId), context?.previousBoardData)
                toastContext?.addToast({
                    severity: 'error',
                    message: err.message,
                })
            },
            onSuccess: () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Saved Changes',
                    duration: 1000,
                })
            },
        }
    )
}

export const useCreateLOCsForBoardPlayer = (
    boardId: string
): UseMutationResult<JSONResponse<DTO.LOC>, Error, DTO.LOC> => {
    const queryClient = useQueryClient()
    return useMutation((values: DTO.LOC) => post<DTO.LOC, DTO.LOC>('/scouting-locs', values), {
        onMutate: (updatedLocs: DTO.LOC) => {
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(getBoardDataQueryKey(boardId))
            if (previousBoardData) {
                const updatedBoardData: DTO.BoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items.map((i) => {
                        if (i.type === 'player' && i.id === updatedLocs.playerId) {
                            return {
                                ...i,
                                player: {
                                    ...i.player,
                                    locBullScout: updatedLocs.locBullseye,
                                    locNowScout: updatedLocs.locNow,
                                    locLowScout: updatedLocs.locLow,
                                    locHighScout: updatedLocs.locHigh,
                                    scoutMostRecentLocs: new Date().toJSON(),
                                },
                            }
                        }
                        return i
                    }),
                }
                queryClient.setQueryData(getBoardDataQueryKey(boardId), updatedBoardData)
            }
            return { previousBoardData }
        },
        onError: (err, _, context) => {
            const queryKey = getBoardDataQueryKey(boardId)
            queryClient.setQueryData(queryKey, context?.previousBoardData)
        },
        onSettled: () => queryClient.invalidateQueries(getBoardDataQueryKey(boardId)),
    })
}

export type CreateSkills = DTO.ScoutingSkills & { playerId: string; scoutEntityId: string }

export const useCreateSkillsForBoardPlayer = (): UseMutationResult<JSONResponse<CreateSkills>, Error, CreateSkills> =>
    useMutation((values: CreateSkills) => post<CreateSkills, CreateSkills>('/scouting-skills', values))

export const getDesignatedBoardId = async (
    designation: Enum.BoardDesignation,
    season: number
): Promise<{ boardId: string } | ''> =>
    (await get<{ boardId: string } | ''>(`/boards/designations/${designation}/${season}`)).data

export const useGetDesignatedBoardId = (
    designation: Enum.BoardDesignation,
    season: number,
    options?: Omit<
        UseQueryOptions<{ boardId: string } | '', Error, { boardId: string } | '', QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<{ boardId: string } | ''> => {
    const { data: session } = useSession()
    return useQuery<{ boardId: string } | '', Error, { boardId: string } | '', QueryKey>(
        ['designatedBoard', designation, season],
        () => getDesignatedBoardId(designation, season),
        {
            ...options,
            enabled: !!session?.roles.featurePermissions['custom-boards'] && options?.enabled !== false,
        }
    )
}

export const getBoardPlayer = async (boardId: string, playerId: string): Promise<DTO.BoardPlayer | ''> =>
    (await get<DTO.BoardPlayer | ''>(`/boards/${boardId}/${playerId}/board-player`)).data

export const useGetBoardPlayer = (
    boardId: string | undefined,
    playerId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.BoardPlayer>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.BoardPlayer | ''> => {
    const { data: session } = useSession()
    // @ts-expect-error boardId and playerId will only get referenced here when it is defined (enabled)
    return useQuery(['boardPlayer', boardId, playerId], () => getBoardPlayer(boardId, playerId), {
        ...options,
        enabled:
            !!boardId &&
            !!playerId &&
            !!session?.roles.featurePermissions['custom-boards'] &&
            options?.enabled !== false,
    })
}

export const getBoardTiers = async (boardId: string): Promise<DTO.BoardTier[]> =>
    (await get<DTO.BoardTier[]>(`/boards/${boardId}/tiers`)).data

export const useGetBoardTers = (
    boardId: string | undefined,
    options?: Omit<UseQueryOptions<DTO.BoardTier[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.BoardTier[]> => {
    const { data: session } = useSession()
    // @ts-expect-error boardId and playerId will only get referenced here when it is defined (enabled)
    return useQuery(['boardTiers', boardId], () => getBoardTiers(boardId), {
        ...options,
        enabled: !!boardId && !!session?.roles.featurePermissions['custom-boards'] && options?.enabled !== false,
    })
}

export const useUpdateBoardPlayerNotes = (
    boardId: string,
    boardPlayerId: string,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse<DTO.BoardPlayer>, Error, DTO.BoardPlayer> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedBoardPlayer: DTO.BoardPlayer) =>
            put<DTO.BoardPlayer, DTO.BoardPlayer>(
                `/boards/${boardId}/notes/player/${boardPlayerId}`,
                updatedBoardPlayer
            ),
        {
            onMutate: (updatedBoardPlayer: DTO.BoardPlayer) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                    getBoardDataQueryKey(boardId)
                )
                if (previousBoardData) {
                    const updatedBoardData: DTO.BoardData = {
                        ...previousBoardData,
                        items: previousBoardData.items.map((i) => {
                            if (i.type === 'player' && i.id === updatedBoardPlayer.id) {
                                return { ...i, notes: updatedBoardPlayer.notes }
                            }
                            return i
                        }),
                    }
                    queryClient.setQueryData(getBoardDataQueryKey(boardId), updatedBoardData)
                }
                return { previousBoardData }
            },
            onError: (err, _, context) => {
                queryClient.setQueryData(getBoardDataQueryKey(boardId), context?.previousBoardData)
            },
            onSuccess: () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Saved Changes',
                    duration: 1000,
                })
            },
        }
    )
}

export const useUpdateBoardTeamNotes = (
    boardId: string,
    teamId: string,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse<DTO.BoardTeam>, Error, DTO.BoardTeam> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedBoardTeam: DTO.BoardTeam) =>
            put<DTO.BoardTeam, DTO.BoardTeam>(`/boards/${boardId}/notes/team/${teamId}`, updatedBoardTeam),
        {
            onMutate: (updatedBoardTeam: DTO.BoardTeam) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(
                    getBoardDataQueryKey(boardId)
                )
                if (previousBoardData) {
                    const updatedBoardData: DTO.BoardData = {
                        ...previousBoardData,
                        items: previousBoardData.items.map((i) => {
                            if (i.type === 'team' && i.id === updatedBoardTeam.id) {
                                return { ...i, notes: updatedBoardTeam.notes }
                            }
                            return i
                        }),
                    }
                    queryClient.setQueryData(getBoardDataQueryKey(boardId), updatedBoardData)
                }
                return { previousBoardData }
            },
            onError: (err, _, context) => {
                queryClient.setQueryData(getBoardDataQueryKey(boardId), context?.previousBoardData)
            },
            onSuccess: () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Saved Changes',
                    duration: 1000,
                })
            },
        }
    )
}

export const useMovePlayer = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.MovePlayerInput> => {
    const queryClient = useQueryClient()
    const queryKey = getBoardDataQueryKey(boardId)
    return useMutation(
        ({ position, id, entityId }: DTO.MovePlayerInput) =>
            put(`/boards/${boardId}/players`, { position, id, entityId }, undefined, {
                'If-Match': version,
            }),
        {
            onMutate: (player: DTO.MovePlayerInput) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
                let updatedBoardData: DTO.BoardData | undefined
                if (previousBoardData) {
                    updatedBoardData = {
                        ...previousBoardData,
                        items: moveItemAndRecalculatePositionsAndRanks(
                            { ...player, type: 'player' },
                            previousBoardData.items
                        ),
                    }
                    queryClient.setQueryData(queryKey, updatedBoardData)
                }
                return { previousBoardData, updatedBoardData }
            },
            onSuccess: ({ headers }, _, context) => {
                const newVersion = headers.get('etag')
                if (typeof newVersion === 'string' && context?.updatedBoardData) {
                    queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
                }
            },
            onError: async (err, _, context) => {
                queryClient.setQueryData(queryKey, context?.previousBoardData)
                await queryClient.invalidateQueries(queryKey)
            },
        }
    )
}
export const useMoveTeam = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.MoveTeamInput> => {
    const queryClient = useQueryClient()
    const queryKey = getBoardDataQueryKey(boardId)
    return useMutation(
        ({ position, id, entityId }: DTO.MoveTeamInput) =>
            put(`/boards/${boardId}/teams`, { position, id, entityId }, undefined, {
                'If-Match': version,
            }),
        {
            onMutate: (team: DTO.MoveTeamInput) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
                let updatedBoardData: DTO.BoardData | undefined
                if (previousBoardData) {
                    updatedBoardData = {
                        ...previousBoardData,
                        items: moveItemAndRecalculatePositionsAndRanks(
                            { ...team, type: 'team' },
                            previousBoardData.items
                        ),
                    }
                    queryClient.setQueryData(queryKey, updatedBoardData)
                }
                return { previousBoardData, updatedBoardData }
            },
            onSuccess: ({ headers }, _, context) => {
                const newVersion = headers.get('etag')
                if (typeof newVersion === 'string' && context?.updatedBoardData) {
                    queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
                }
            },
            onError: async (err, _, context) => {
                queryClient.setQueryData(queryKey, context?.previousBoardData)
                await queryClient.invalidateQueries(queryKey)
            },
        }
    )
}
export const useMoveTier = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.MoveTierInput> => {
    const queryClient = useQueryClient()
    const queryKey = getBoardDataQueryKey(boardId)
    return useMutation(
        ({ position, name }: DTO.MoveTierInput) =>
            put(`/boards/${boardId}/tiers`, { position, name }, undefined, {
                'If-Match': version,
            }),
        {
            onMutate: (tier: DTO.MoveTierInput) => {
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
                let updatedBoardData: DTO.BoardData | undefined
                if (previousBoardData) {
                    updatedBoardData = {
                        ...previousBoardData,
                        items: moveItemAndRecalculatePositionsAndRanks(
                            { ...tier, type: 'tier' },
                            previousBoardData.items
                        ),
                    }
                    queryClient.setQueryData(queryKey, updatedBoardData)
                }
                return { previousBoardData, updatedBoardData }
            },
            onSuccess: ({ headers }, _, context) => {
                const newVersion = headers.get('etag')
                if (typeof newVersion === 'string' && context?.updatedBoardData) {
                    queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
                }
            },
            onError: async (err, _, context) => {
                queryClient.setQueryData(queryKey, context?.previousBoardData)
                await queryClient.invalidateQueries(queryKey)
            },
        }
    )
}

export const useMarkRanked = (
    boardType: Enum.BoardEntityType,
    { boardId, version }: UpdateBoardParams,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    const queryKey = getBoardDataQueryKey(boardId)
    const itemType: DTO.BoardData['items'][number]['type'] = boardType === 'TEAM' ? 'team' : 'player'
    return useMutation(
        (itemId: string) =>
            post(`/boards/${boardId}/${itemType}s/${itemId}/rank`, undefined, undefined, {
                'If-Match': version,
            }),
        {
            onMutate: (itemId: string) => {
                let updatedBoardData: DTO.BoardData | undefined
                const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
                if (previousBoardData) {
                    updatedBoardData = {
                        ...previousBoardData,
                        items: previousBoardData.items.map((i) => {
                            if (i.type === itemType && i.id === itemId) {
                                return { ...i, isRanked: true }
                            }
                            return i
                        }),
                    }
                    queryClient.setQueryData(queryKey, updatedBoardData)
                }
                return { previousBoardData, updatedBoardData }
            },
            onSuccess: ({ headers }, _, context) => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Saved Changes',
                    duration: 1000,
                })

                const newVersion = headers.get('etag')
                if (typeof newVersion === 'string' && context?.updatedBoardData) {
                    queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
                }
            },
            onError: async (err, _, context) => {
                toastContext?.addToast({
                    severity: 'error',
                    message: err.message,
                })

                queryClient.setQueryData(queryKey, context?.previousBoardData)
                await queryClient.invalidateQueries(queryKey)
            },
        }
    )
}

type DeleteItemVariables = { id: LiteralUnion<DTO.BoardTier['id'], string>; position: number }
export type DeleteBoardItemFn = (variables: DeleteItemVariables) => Promise<JSONResponse>

export const useDeleteItem = (
    boardType: Enum.BoardEntityType,
    { boardId, version }: UpdateBoardParams,
    toastContext: ToastContextInterface | null
): UseMutationResult<JSONResponse, Error, DeleteItemVariables> => {
    const queryClient = useQueryClient()
    const queryKey = getBoardDataQueryKey(boardId)
    const mutate: DeleteBoardItemFn = ({ id, position }: DeleteItemVariables) => {
        let itemType: DTO.BoardData['items'][number]['type']
        let itemId: string
        if (id.startsWith('tier-')) {
            itemType = 'tier'
            itemId = position.toString()
        } else {
            itemType = boardType === 'TEAM' ? 'team' : 'player'
            itemId = id
        }

        return remove(`/boards/${boardId}/${itemType}s/${itemId}`, undefined, {
            'If-Match': version,
        })
    }
    return useMutation(mutate, {
        onMutate: ({ id, position }) => {
            let updatedBoardData: DTO.BoardData | undefined
            const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
            if (previousBoardData) {
                updatedBoardData = {
                    ...previousBoardData,
                    items: previousBoardData.items
                        .filter((i) => i.id !== id)
                        .map((i) => {
                            if (i.position > position) {
                                let newItem = { ...i, position: i.position - 1 }
                                if (i.type !== 'tier') {
                                    newItem = { ...i, rank: i.rank - 1 }
                                }
                                return newItem
                            }
                            return i
                        }),
                }
                queryClient.setQueryData(queryKey, updatedBoardData)
            }
            return { previousBoardData, updatedBoardData }
        },
        onSuccess: ({ headers }, _, context) => {
            toastContext?.addToast({
                severity: 'success',
                message: 'Deleted Item',
                duration: 1000,
            })

            const newVersion = headers.get('etag')
            if (typeof newVersion === 'string' && context?.updatedBoardData) {
                queryClient.setQueryData(queryKey, { ...context.updatedBoardData, version: newVersion })
            }
        },
        onError: async (err, _, context) => {
            toastContext?.addToast({
                severity: 'error',
                message: err.message,
            })

            queryClient.setQueryData(queryKey, context?.previousBoardData)
            await queryClient.invalidateQueries(queryKey)
        },
    })
}

export const useSQLQuery = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse<DTO.SQLQueryResp>, Error, DTO.SQLQuery> => {
    const queryClient = useQueryClient()
    return useMutation(
        (values: DTO.SQLQuery) =>
            post<DTO.SQLQueryResp, DTO.SQLQuery>(`/boards/${boardId}/script-query`, values, undefined, {
                'If-Match': version,
            }),
        {
            onSuccess: (data) => {
                const newVersion = data.headers.get('etag')
                if (typeof newVersion === 'string') {
                    const queryKey = getBoardDataQueryKey(boardId)
                    const previousBoardData: DTO.BoardData | undefined = queryClient.getQueryData(queryKey)
                    queryClient.setQueryData(queryKey, { ...previousBoardData, version: newVersion })
                }
            },
        }
    )
}

export const useSQLImport = ({
    boardId,
    version,
}: UpdateBoardParams): UseMutationResult<JSONResponse, Error, DTO.SQLImport> => {
    const queryClient = useQueryClient()
    return useMutation(
        ({ doNotAdd, doNotDelete, sqlScript, serverPassword }: DTO.SQLImport) =>
            post<unknown, DTO.SQLImport>(
                `/boards/${boardId}/script-import`,
                { sqlScript, serverPassword, doNotAdd, doNotDelete },
                undefined,
                {
                    'If-Match': version,
                }
            ),
        { onSettled: () => queryClient.invalidateQueries(getBoardDataQueryKey(boardId)) }
    )
}
