import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const useSimilarPlayers = (playerId: string, season: number): UseQueryResult<DTO.SimilarPlayer[]> =>
    useQuery(
        ['similar-players', playerId, season],
        async () => (await get<DTO.SimilarPlayer[]>(`/profile/${playerId}/${season}/similar-players`)).data
    )

export default useSimilarPlayers
