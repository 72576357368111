import type { UseQueryResult, QueryKey, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post, remove } from '../api'

export type PlayerSigningFormValues = Pick<
    DTO.PlayerSigning,
    | 'playerId'
    | 'playerUrlSlug'
    | 'playerName'
    | 'season'
    | 'signingTeamId'
    | 'salaryAmount'
    | 'contractYears'
    | 'contractType'
    | 'formerTeamId'
    | 'reportedById'
    | 'reportedByName'
    | 'reportedByUrlSlug'
    | 'reportedByType'
    | 'reportedAt'
    | 'signingMethod'
    | 'salaryAmountYear1'
    | 'salaryAmountYear2'
    | 'salaryAmountYear3'
    | 'salaryAmountYear4'
    | 'salaryAmountYear5'
    | 'notes'
    | 'formerTeamAbbr'
    | 'formerTeamLogo'
    | 'formerTeamUrlSlug'
>

export const useQueryPlayerSigningsForSeason = (season: number): UseQueryResult<DTO.PlayerSigning[]> =>
    useQuery<DTO.PlayerSigning[], Error, DTO.PlayerSigning[], QueryKey>(
        ['player-signings', season],
        async () => (await get<DTO.PlayerSigning[]>(`/player-signings/${season}`)).data
    )

export const useSavePlayerSigning = (): UseMutationResult<JSONResponse, Error, PlayerSigningFormValues> => {
    const queryClient = useQueryClient()
    return useMutation(
        (signing: PlayerSigningFormValues) => post<undefined, PlayerSigningFormValues>(`/player-signings`, signing),
        {
            onSuccess: () => queryClient.invalidateQueries(['player-signings']),
        }
    )
}

export const useDeletePlayerSigning = (): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation((playerSigningId: string) => remove(`/player-signings/${playerSigningId}`), {
        onSuccess: () => queryClient.invalidateQueries(['player-signings']),
    })
}
