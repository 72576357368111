import type { JSONResponse } from '../api'
import type { UseQueryOptions, UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { get, post, serverGet } from '../api'

export const serverGetWatchListItems = async (
    session: Session | null
): Promise<{ boards: string[]; players: DTO.PostTag[] }> =>
    (await serverGet<{ boards: string[]; players: DTO.PostTag[] }>('/user-watch-list', session)).data

export const useUserWatchListItems = (): UseQueryResult<{ boards: string[]; players: DTO.PostTag[] }> =>
    useQuery(
        ['userWatchList'],
        async () => (await get<{ boards: string[]; players: DTO.PostTag[] }>('/user-watch-list')).data
    )

export const serverGetUserWatchListInfo = async (session: Session | null): Promise<DTO.UserWatchListInfo[] | ''> =>
    (await serverGet<DTO.UserWatchListInfo[] | ''>('/user-watch-list/info', session)).data

const getUserWatchListInfo = async (): Promise<DTO.UserWatchListInfo[] | ''> =>
    (await get<DTO.UserWatchListInfo[] | ''>('/user-watch-list/info')).data

const useUserWatchList = (
    options?: Omit<UseQueryOptions<DTO.UserWatchListInfo[] | ''>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.UserWatchListInfo[] | ''> =>
    useQuery(['userWatchListInfo'], () => getUserWatchListInfo(), options)

type AddItemInput = { players: string[]; boards: string[] }
type AddItemResp = { message?: string; warning?: string }

export const useAddItemToWatchList = (): UseMutationResult<JSONResponse<AddItemResp>, Error, AddItemInput> => {
    const queryClient = useQueryClient()
    return useMutation((items: AddItemInput) => post<AddItemResp, AddItemInput>(`/user-watch-list/`, items), {
        onSettled: async () => {
            void queryClient.invalidateQueries(['userWatchListInfo'])
            await queryClient.invalidateQueries(['userWatchList'])
        },
    })
}

const getWatchListPlayerInfo = async (playerId: string, season: number): Promise<DTO.WatchListPlayerInfo> =>
    (await get<DTO.WatchListPlayerInfo>(`/user-watch-list/info/${playerId}/${season}`)).data

export const useWatchListPlayerInfo = (
    playerId: string,
    season: number,
    options?: Omit<UseQueryOptions<DTO.WatchListPlayerInfo>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.WatchListPlayerInfo> =>
    useQuery(['userWatchList', playerId], () => getWatchListPlayerInfo(playerId, season), options)

export default useUserWatchList
