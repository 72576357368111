import type { UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get, staticGet } from '../api'

type TeamLeagueQueryParam = DTO.Team['leagueId'] | DTO.Team['leagueId'][]

export const queryKey = (league: TeamLeagueQueryParam, conference: string): QueryKey => ['teams', league, conference]

export const queryTeams = async (league?: TeamLeagueQueryParam, conference?: string): Promise<DTO.Team[]> =>
    (await get<DTO.Team[]>('/teams', { league, conference })).data
// ONLY use in `getStaticPaths` or `getStaticProps` functions
export const staticQueryTeams = async (league?: TeamLeagueQueryParam, conference?: string): Promise<DTO.Team[]> =>
    (await staticGet<DTO.Team[]>('/teams', { league, conference })).data

const useTeams = (league?: TeamLeagueQueryParam, conference?: string | null): UseQueryResult<DTO.Team[]> =>
    // @ts-expect-error league will only get referenced here when it is defined (enabled)
    useQuery<DTO.Team[]>(queryKey(league, conference), () => queryTeams(league, conference), { enabled: !!league })

export default useTeams
