import type { UseQueryResult, QueryKey, UseQueryOptions } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import { useQuery } from '@tanstack/react-query'
import { get, staticGet } from '../api'

export const queryKey = (): QueryKey => ['players']
const apiPath = (): string => '/players'

export const queryPlayers = async (): Promise<DTO.Player[]> => (await get<DTO.Player[]>(apiPath())).data
// ONLY use in `getStaticPaths` or `getStaticProps` functions
export const staticQueryPlayers = async (params?: RequestParams): Promise<DTO.Player[]> =>
    (await staticGet<DTO.Player[]>(apiPath(), params)).data

const usePlayers = (): UseQueryResult<DTO.Player[]> => useQuery(queryKey(), queryPlayers)

export const useNBAPlayers = (): UseQueryResult<DTO.DepthChartResp> =>
    useQuery(['nba-players'], async () => (await get<DTO.DepthChartResp[]>(`/players?active=true&nba=true`)).data)

export const getPlayersByTeamId = async (teamId: string): Promise<DTO.Player[]> =>
    (await get<DTO.Player[]>(`/teams/${teamId}/roster`)).data

export const useGetPlayersByTeamId = (
    teamId: string | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.TeamRosterPlayer[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.TeamRosterPlayer[]> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['team-roster', teamId], () => getPlayersByTeamId(teamId), {
        ...options,
        enabled: !!teamId && options?.enabled !== false,
    })

export default usePlayers
