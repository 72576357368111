import type { UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, remove, post } from '../api'

export const useFeedOverrides = (): UseQueryResult<DTO.FeedOverride[]> =>
    useQuery(['feed-overrides'], async () => (await get<DTO.FeedOverride[]>('/feed-overrides')).data)

export const useCreateFeedOverrides = (): UseMutationResult<JSONResponse, Error, DTO.CreateFeedOverride> => {
    const queryClient = useQueryClient()
    return useMutation((data: DTO.CreateFeedOverride) => post('/feed-overrides', data), {
        onSuccess: () => queryClient.invalidateQueries(['feed-overrides']),
    })
}

export type ToggleFeedOverride = DTO.FeedOverride & { active: boolean }
export const useToggleFeedOverrides = (): UseMutationResult<JSONResponse, Error, ToggleFeedOverride> => {
    const queryClient = useQueryClient()
    return useMutation(
        ({
            active,
            userGroup: { userGroupId },
            type,
            player: { playerId },
            contentPermission: { contentPermissionId },
        }: ToggleFeedOverride) => {
            const route = `/feed-overrides/${userGroupId}/${type}/${playerId}/${contentPermissionId}`
            return active ? post<undefined, undefined>(route, undefined) : remove(route)
        },
        { onSuccess: () => queryClient.invalidateQueries(['feed-overrides']) }
    )
}
