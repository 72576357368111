import type { JSONResponse } from '../api'
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post, serverGet } from '../api'

export const serverQueryOrgPlayers = async (session: Session | null): Promise<DTO.OrgPlayers> =>
    (await serverGet<DTO.OrgPlayers>(`/org-players`, session)).data

export const queryOrgPlayers = async (): Promise<DTO.OrgPlayers> => (await get<DTO.OrgPlayers>(`/org-players`)).data

const useOrgPlayers = (): UseQueryResult<DTO.OrgPlayers> => useQuery(['org-players'], queryOrgPlayers)

export const useTeamOrgPlayers = (
    levels?: Enum.OrgPlayerLevels[],
    active?: boolean
): UseQueryResult<DTO.TeamOrgPlayer[]> =>
    useQuery<DTO.TeamOrgPlayer[]>(
        ['org-players', levels, active],
        async () => (await get<DTO.TeamOrgPlayer[]>('/org-players/team-org-players', { levels, active })).data
    )

export const useSaveOrgPlayer = (): UseMutationResult<JSONResponse<DTO.OrgPlayers>, Error, DTO.TeamOrgPlayer> => {
    const queryClient = useQueryClient()
    return useMutation((player: DTO.TeamOrgPlayer) => post<DTO.OrgPlayers, DTO.TeamOrgPlayer>('/org-players', player), {
        onSuccess: (resp) => {
            queryClient.setQueryData(['org-players'], resp.data)
        },
    })
}

export default useOrgPlayers
