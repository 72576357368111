import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const getPositionVersatilityPlayerSeason = async (
    playerId: string,
    season: number
): Promise<DTO.PositionVersatilitySeason | ''> =>
    (await get<DTO.PositionVersatilitySeason | ''>(`/profile/${playerId}/${season}/position-versatility`)).data

export const useGetPositionVersatilityPlayerSeason = ({
    playerId,
    season,
    options,
}: {
    playerId: string | null | undefined
    season: number | null | undefined
    options?: Omit<
        UseQueryOptions<DTO.PositionVersatilitySeason | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.PositionVersatilitySeason | ''> =>
    useQuery<DTO.PositionVersatilitySeason | ''>(
        ['position-versatility', playerId, season],
        // @ts-expect-error playerId and season will only get referenced here when they are defined (enabled)
        () => getPositionVersatilityPlayerSeason(playerId, season),
        { ...options, enabled: !!playerId && !!season && options?.enabled !== false }
    )

const getPositionVersatilityForPastNinetyDays = async (
    playerId: string,
    scoutEntityId: string
): Promise<DTO.PositionVersatility | ''> =>
    (await get<DTO.PositionVersatility>(`/position-versatility/${playerId}/${scoutEntityId}/past-ninety-days`)).data

const useGetPositionVersatilityForPastNinetyDays = ({
    playerId,
    scoutEntityId,
    options,
}: {
    playerId: string | null
    scoutEntityId: string
    options: Omit<
        UseQueryOptions<DTO.PositionVersatility | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.PositionVersatility | ''> =>
    useQuery<DTO.PositionVersatility | ''>(
        ['position-versatility', playerId, scoutEntityId],
        // @ts-expect-error playerId will only get referenced here when it is defined (enabled)
        () => getPositionVersatilityForPastNinetyDays(playerId, scoutEntityId),
        { enabled: !!playerId, ...options }
    )
export default useGetPositionVersatilityForPastNinetyDays
