'use client'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type Breakpoints = {
    isMobile: boolean
    isTablet: boolean
    isLandscape: boolean
    isDesktop: boolean
    isPrint: boolean
}

const useBreakPoints = (): Breakpoints => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'))
    const isLandscape = useMediaQuery(theme.breakpoints.between('md', 'lg'))
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'))
    const isPrint = useMediaQuery(theme.breakpoints.down('print'))

    return { isMobile, isTablet, isLandscape, isDesktop, isPrint }
}

export default useBreakPoints
