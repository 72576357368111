import React from 'react'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import Badge from '@mui/material/Badge'
import Box from '@mui/material/Box'
import FormLabel from '@mui/material/FormLabel'

export const ageLOCCurveColorScheme = {
    Franchise: '#263238',
    Core: '#37474f',
    'Top Starter': '#455a64',
    Starter: '#546e7a',
    'Key Reserve': '#607d8b',
    Rotation: '#78909c',
    Roster: '#90a4ae',
    'Minor League': '#b0bec5',
    'Below Minor League': '#cfd8dc',
}

export const ageLOCCurveOptions: { active: boolean; name: DTO.LOCName }[] = [
    { active: false, name: 'Franchise' },
    { active: false, name: 'Core' },
    { active: false, name: 'Top Starter' },
    { active: false, name: 'Starter' },
    { active: false, name: 'Key Reserve' },
    { active: false, name: 'Rotation' },
    { active: false, name: 'Roster' },
    { active: false, name: 'Minor League' },
]

type AgeLOCCurvesProps = {
    setFilterParams: (params: DTO.PlayerCompsFilterParams) => void
    filterParams: DTO.PlayerCompsFilterParams
}

const AgeLOCCurves = ({ filterParams, setFilterParams }: AgeLOCCurvesProps): JSX.Element => {
    const playerCompAgeLOCCurves = filterParams.ageLOCCurves as DTO.PlayerCompAgeLOCCurves
    return (
        <Box display="flex" flexDirection="column">
            <FormLabel>Age LOC Curves</FormLabel>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    {ageLOCCurveOptions.map((a) => {
                        const color = ageLOCCurveColorScheme[a.name]
                        return (
                            <Box key={a.name} display="flex" alignItems="center" height={30}>
                                <Badge
                                    sx={{
                                        '& .MuiBadge-badge': {
                                            backgroundColor: color,
                                            borderRadius: 0,
                                        },
                                    }}
                                    badgeContent=" "
                                    variant="dot"
                                />
                                <FormControlLabel
                                    sx={{
                                        marginLeft: 1,
                                        '.MuiFormControlLabel-label': {
                                            textOverflow: 'ellipsis',
                                            maxWidth: { xs: '100%', lg: 90, xl: 131 },
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            fontSize: '14px',
                                        },
                                    }}
                                    control={
                                        <Switch
                                            checked={playerCompAgeLOCCurves[a.name].active}
                                            onChange={(e) => {
                                                const name = e.target.name as DTO.LOCName
                                                const value = !playerCompAgeLOCCurves[name].active
                                                setFilterParams({
                                                    ...filterParams,
                                                    ageLOCCurves: {
                                                        ...playerCompAgeLOCCurves,
                                                        [name]: { active: value, color },
                                                    },
                                                })
                                            }}
                                            sx={{
                                                '&&': {
                                                    '.MuiButtonBase-root&.MuiSwitch-switchBase.Mui-checked': {
                                                        color,
                                                        backgroundColor: color,
                                                    },
                                                    '.Mui-checked': {
                                                        color,
                                                    },
                                                    '.Mui-checked+.MuiSwitch-track': {
                                                        color,
                                                        backgroundColor: color,
                                                    },
                                                },
                                            }}
                                            name={a.name}
                                            size="small"
                                        />
                                    }
                                    label={a.name}
                                />
                            </Box>
                        )
                    })}
                </FormGroup>
            </FormControl>
        </Box>
    )
}

export default AgeLOCCurves
