import type { UseQueryResult } from '@tanstack/react-query'
import type { AgentInfoFilterParams } from '@/components/player-agents/AgentInfoFilters'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

type QueryKey = ['agents']

export const agentsQueryKey: QueryKey = ['agents']

const apiPath = '/agents'

const getAgentInfo = async (filters: AgentInfoFilterParams): Promise<DTO.AgentInfo[]> =>
    (await get<DTO.AgentInfo[]>(`${apiPath}/agent-info`, filters)).data

export const useGetAgentInfo = (filters: AgentInfoFilterParams): UseQueryResult<DTO.AgentInfo[]> =>
    useQuery([...agentsQueryKey, 'agent-info', filters], () => getAgentInfo(filters))

export const getAgentByPlayerId = async (playerId: string): Promise<DTO.Agent> =>
    (await get<DTO.Agent>(`${apiPath}/by-player-id/${playerId}`)).data

const useGetAgentByPlayerId = (playerId: string): UseQueryResult<DTO.Agent> =>
    useQuery([...agentsQueryKey], () => getAgentByPlayerId(playerId))

const getAgentById = async (agentId: string): Promise<DTO.Agent> => (await get<DTO.Agent>(`${apiPath}/${agentId}`)).data

export const useGetAgentById = (agentId: string | undefined): UseQueryResult<DTO.Agent> =>
    // @ts-expect-error agentId will only get referenced here when it is defined (enabled)
    useQuery([...agentsQueryKey, agentId], () => getAgentById(agentId), {
        enabled: !!agentId,
    })

export default useGetAgentByPlayerId
