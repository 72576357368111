'use client'

import type { Except } from 'type-fest'
import { usePathname, useSearchParams } from 'next/navigation'
import { useCallback, useState, useMemo, useRef } from 'react'
import { useRouter } from 'next-nprogress-bar'
import { useUserFollowing } from './useUserFollowing'
import { objectToURLSearchParams, URLSearchParamsToObject } from '@/lib/routing/searchParams'

type SearchKey = keyof DTO.AdvancedPostSearch
type PostSearch = Except<DTO.AdvancedPostSearch, 'page'>
type PostSearchQueryParams<U extends string | undefined> = U extends string
    ? PostSearch & { [RouteParam in U]: string }
    : PostSearch
type FeedQueryParams<T extends Enum.PostFilter | Enum.PostFilterWithFollowing, U extends string | undefined> = [
    T | '',
    (type: T | '') => void,
    PostSearchQueryParams<U>,
    boolean,
]

function useFeedQueryParams<
    U extends undefined = undefined,
    T extends Enum.PostFilterWithFollowing = Enum.PostFilterWithFollowing,
>(): FeedQueryParams<T, U>
function useFeedQueryParams<U extends string, T extends Enum.PostFilter = Enum.PostFilter>(
    routeParams: U[],
    ignoreFilters?: SearchKey[]
): FeedQueryParams<T, U>
function useFeedQueryParams<U extends string | undefined, T extends Enum.PostFilter | Enum.PostFilterWithFollowing>(
    routeParams?: U[],
    ignoreFilters?: SearchKey[]
): FeedQueryParams<T, U> {
    const router = useRouter()
    const searchParams = useSearchParams()
    const pathname = usePathname()
    const { data: userFollowing } = useUserFollowing({ enabled: !routeParams })
    const pageLoadTime = useRef<string>(new Date().toISOString())
    const hasQueryParams = useMemo(() => {
        if (!searchParams || Array.from(searchParams).length === 0) return false
        const ignoreFiltersSet = new Set<SearchKey>(ignoreFilters || []).add('type')
        const routeParamsSet = routeParams && new Set<U>(routeParams)
        return Array.from(searchParams.keys()).some(
            (k) => !routeParamsSet?.has(k as U) && !ignoreFiltersSet.has(k as SearchKey)
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams])
    const [type, setType] = useState(() => {
        let viewingType: T | '' = ''
        if (searchParams?.get('type')) viewingType = searchParams.get('type') as T | ''
        else if (!hasQueryParams && !routeParams && userFollowing) {
            const hasUserFollowingPreferences = Object.values(userFollowing).some((x) => Array.isArray(x) && x.length)
            if (hasUserFollowingPreferences) (viewingType as Enum.PostFilterWithFollowing) = 'FOLLOWING'
        }
        return viewingType
    })

    const setTypeStateAndQuery = useCallback(
        (newQueryType: T | '') => {
            setType(newQueryType)

            // eslint-disable-next-line prefer-const
            let { type: oldQueryType, ...query } = URLSearchParamsToObject(searchParams) as DTO.AdvancedPostSearch

            // reset the form values depending on when the search type changes
            if (newQueryType === 'FOLLOWING') {
                const { minDate, maxDate, ...rest } = query
                if (Object.keys(rest).length) {
                    query = {}
                    if (minDate) query.minDate = minDate
                    if (maxDate) query.maxDate = maxDate
                }
            } else if (newQueryType === 'MY_POSTS') {
                const { author, ...rest } = query
                if (author) query = rest
            } else if (newQueryType) {
                const { content, ...rest } = query
                if (content) query = rest
            }

            if (newQueryType !== 'INTEL' && newQueryType !== 'AM_INTEL' && newQueryType !== 'PRO_INTEL') {
                const { intelTextTypes, ...rest } = query
                if (intelTextTypes) query = rest
            }

            const params = objectToURLSearchParams({ ...query, type: newQueryType })

            return router.replace(`${pathname as string}?${params}`)
        },
        [router, searchParams, setType, pathname]
    )

    return [
        type,
        setTypeStateAndQuery,
        { ...(URLSearchParamsToObject(searchParams) as PostSearchQueryParams<U>), pageLoadTime: pageLoadTime.current },
        hasQueryParams,
    ]
}

export default useFeedQueryParams
