'use client'

import type { useFormik } from 'formik'
import { useState } from 'react'
import usePlayer from './usePlayer'
import useWatchVariable from './useWatchVariable'
import useTeam from './useTeam'

interface PlayerLevelReport {
    tags: DTO.PostTag[]
    level: Enum.ReportLevel
}
type PlayerLevelReportFormik = ReturnType<typeof useFormik<PlayerLevelReport>>

export default (
    formik: Pick<PlayerLevelReportFormik, 'values' | 'setFieldValue'>
): ((newLevel: Enum.ReportLevel) => Promise<void>) => {
    const tagPlayer = formik.values.tags.find((tag) => tag.entityType === 'player') as DTO.PlayerTag | undefined
    // if the tag comes from (is mapped from) the search input, it will not have a player level
    // lookup the player level using the usePlayer hook, which is used on multiple forms and is most likely already cached
    const { data: player } = usePlayer(tagPlayer?.entitySlug, { enabled: !tagPlayer?.level })

    const tagTeam = (tagPlayer ? undefined : formik.values.tags.find((tag) => tag.entityType === 'team')) as
        | DTO.TeamTag
        | undefined
    // tagTeam is only defined when tagPlayer is not
    const { data: team } = useTeam(tagTeam?.entitySlug, { enabled: !tagPlayer?.entityId && !!tagTeam?.entitySlug })

    const [isLevelManuallyChanged, setIsLevelManuallyChanged] = useState(false)

    // reset isLevelManuallyChanged flag when the player changes
    useWatchVariable(
        ([playerId, teamId]) => {
            if (playerId) setIsLevelManuallyChanged(false)
            if (teamId) setIsLevelManuallyChanged(false)
        },
        [tagPlayer?.entityId, tagTeam?.entitySlug]
    )

    // set the report level to the player level if it has not been overriden
    useWatchVariable(
        async ([playerLevel, teamLeague, formLevel, isManuallyChanged]) => {
            if (playerLevel && playerLevel !== formLevel && !isManuallyChanged) {
                await formik.setFieldValue('level', playerLevel)
            }
            // Don't set the level if teamLeague is International or undefined
            if (teamLeague && teamLeague !== 'International') {
                if (teamLeague === 'College' || teamLeague === 'OTE' || teamLeague === 'Prep') {
                    await formik.setFieldValue('level', 'AM')
                } else {
                    await formik.setFieldValue('level', 'PRO')
                }
            }
        },
        [player?.level || tagPlayer?.level, team?.leagueId, formik.values.level, isLevelManuallyChanged] as const
    )

    return async (newLevel) => {
        setIsLevelManuallyChanged(true)
        await formik.setFieldValue('level', newLevel)
    }
}
