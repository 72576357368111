'use client'

import React, { memo } from 'react'
import { useSession, signIn as nextAuthSignIn, signOut as nextAuthSignOut } from 'next-auth/react'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import LogoutIcon from '@mui/icons-material/Logout'
import { useSearchParams } from 'next/navigation'

export const riseSignoutUri = `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/auth/signout`
export const azureADSignoutUrl = `https://login.microsoftonline.com/${process.env.NEXT_PUBLIC_AZURE_AD_TENANT_ID}/oauth2/logout?post_logout_redirect_uri=${riseSignoutUri}`
export const isProdEnv = process.env.NEXT_PUBLIC_NEXTAUTH_URL.startsWith(process.env.NEXT_PUBLIC_PRODUCTION_URL)

export const signIn = (callbackUrl: string | null | undefined): void => {
    void nextAuthSignIn(
        'azure-ad',
        { callbackUrl: callbackUrl || process.env.NEXT_PUBLIC_NEXTAUTH_URL },
        { prompt: 'select_account' }
    )
}

export const signOut = (): void =>
    void nextAuthSignOut({ callbackUrl: `${process.env.NEXT_PUBLIC_NEXTAUTH_URL}/auth/signin` })

// https://react.dev/learn/you-might-not-need-an-effect#resetting-all-state-when-a-prop-changes
type ForceSignInOnSessionChangesProps = {
    children: JSX.Element
    isUser: boolean
    isLoading: boolean
    callbackUrl: string | null | undefined
}
const ForceSignInOnSessionChanges = ({
    children,
    isUser,
    isLoading,
    callbackUrl,
}: ForceSignInOnSessionChangesProps): JSX.Element | null => {
    if (isLoading) return null
    if (isUser) return children

    signIn(callbackUrl)
    return null
}
const MemoizedForceSignInOnSessionChanges = memo(
    ForceSignInOnSessionChanges,
    (prevProps, nextProps) => prevProps.isUser === nextProps.isUser && prevProps.isLoading === nextProps.isLoading
)

type AuthProps = {
    children: JSX.Element
}
const Auth = ({ children }: AuthProps): JSX.Element | null => {
    const searchParams = useSearchParams()
    const callbackUrl = searchParams?.get('callbackUrl')
    const { data: session, status } = useSession({
        required: true,
        onUnauthenticated() {
            signIn(callbackUrl)
        },
    })

    return (
        <MemoizedForceSignInOnSessionChanges
            isUser={!!session?.user}
            isLoading={status === 'loading'}
            callbackUrl={callbackUrl}
        >
            {children}
        </MemoizedForceSignInOnSessionChanges>
    )
}

export const LogoutMenuItem = (): JSX.Element => (
    <Button
        component="button"
        sx={{
            color: 'common.black',
            '&:hover': {
                color: 'initial',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
            textDecoration: 'none',
            textTransform: 'none',
            justifyContent: 'flex-start',
            height: 36,
            paddingX: 0,
            width: '100%',
        }}
        onClick={signOut}
    >
        <MenuItem sx={{ gap: 1, '&:hover': { backgroundColor: 'transparent' } }}>
            <LogoutIcon style={{ color: 'grey', fontSize: 'inherit' }} />
            Log Out
        </MenuItem>
    </Button>
)

export default Auth
