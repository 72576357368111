import type { JSONResponse } from '../api'
import type { UseQueryResult, UseMutationResult, UseQueryOptions } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import type { InjuryFilterParams } from 'app/(nonEntity)/injuries/injuries'
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query'
import { post, get, serverGet } from '../api'

const useInjuryHistory = (playerId: string | undefined, season: number): UseQueryResult<DTO.InjuryHistory> =>
    useQuery(
        ['injury-history', playerId, season],
        async () => (await get<DTO.InjuryHistory>(`/profile/${playerId as string}/${season}/injury-history`)).data,
        { enabled: !!playerId && !!season }
    )

export const serverGetCurrentInjuries = async (
    session: Session | null,
    params: CurrentInjuryParams
): Promise<DTO.CurrentInjuries[]> =>
    (await serverGet<DTO.CurrentInjuries[]>(`/injury/current-injuries`, session, params)).data

export const useCompleteInjuryHistory = (
    playerId: string | undefined
): UseQueryResult<DTO.PlayerCompleteInjuryHistory> =>
    useQuery(
        ['complete-injury-history', playerId],
        // playerId will be defined in hook is enabled
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.PlayerCompleteInjuryHistory>(`/injury/player/${playerId}/history`)).data,
        { enabled: !!playerId }
    )

export const useInjuryHistoryComp = (
    league: Enum.League | undefined,
    secondaryPosition: string | null,
    age: number | null
): UseQueryResult<DTO.InjuryHistorySeason> => {
    const roundedAge = age && Math.round(age)
    return useQuery(
        ['injury-history-comp', league, secondaryPosition, roundedAge],
        async () =>
            (
                await get<DTO.InjuryHistorySeason>(
                    `/profile/injury-comps/${league || ''}/${secondaryPosition || ''}/${roundedAge || ''}`
                )
            ).data,
        { enabled: !!league && !!secondaryPosition && !!roundedAge }
    )
}

export const useUpdateInjuryNotes = (
    filterParams?: InjuryFilterParams
): UseMutationResult<JSONResponse<DTO.PlayerInjuryHistory>, Error, DTO.PlayerInjuryHistory> => {
    const queryClient = useQueryClient()
    return useMutation(
        (injuryUpdate: DTO.PlayerInjuryHistory) =>
            post<DTO.PlayerInjuryHistory, DTO.PlayerInjuryHistory>(
                `/injury/${injuryUpdate.injuryId}/notes`,
                injuryUpdate
            ),
        {
            onSettled: () => {
                void queryClient.invalidateQueries(['complete-injury-history', filterParams?.playerId])
                void queryClient.invalidateQueries(['injury-dashboard', filterParams])
            },
        }
    )
}

export type CurrentInjuryParams = {
    teamId?: string
    league?: Enum.League
}

const queryCurrentInjuries = async (params: CurrentInjuryParams): Promise<DTO.CurrentInjuries[]> =>
    (await get<DTO.CurrentInjuries[]>(`/injury/current-injuries`, params)).data

export const useCurrentInjuries = (
    params: CurrentInjuryParams,
    options?: Omit<
        UseQueryOptions<DTO.CurrentInjuries[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.CurrentInjuries[]> =>
    useQuery<DTO.CurrentInjuries[]>(['current-injuries', params], () => queryCurrentInjuries(params), {
        enabled: options?.enabled !== false,
    })

const queryInjuryDashboard = async (params: InjuryFilterParams): Promise<DTO.PlayerInjuryHistory[]> =>
    (await get<DTO.PlayerInjuryHistory[]>(`/injury/injuries`, params)).data

export const useInjuryDashboard = (
    params: InjuryFilterParams,
    options?: Omit<
        UseQueryOptions<DTO.PlayerInjuryHistory[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerInjuryHistory[]> =>
    useQuery<DTO.PlayerInjuryHistory[]>(['injury-dashboard', params], () => queryInjuryDashboard(params), {
        enabled: options?.enabled !== false,
    })

export type InjuryNewsParams = {
    playerId?: string | null
    injuryId?: string | null
}

const queryInjuryNews = async (params: InjuryNewsParams): Promise<DTO.InjuryNewsResp[]> =>
    (await get<DTO.InjuryNewsResp[]>(`/injury/news`, params)).data

export const useInjuryNews = (
    params: InjuryNewsParams,
    options?: Omit<
        UseQueryOptions<DTO.InjuryNewsResp[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.InjuryNewsResp[]> =>
    useQuery<DTO.InjuryNewsResp[]>(['injury-news', params.playerId], () => queryInjuryNews(params), {
        enabled: (!!params.playerId || !!params.injuryId) && options?.enabled !== false,
    })

export default useInjuryHistory
