import type { UseQueryOptions, UseQueryResult, QueryKey } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { getLeagueOrgTeamSlug, knicksSlug, westchesterSlug } from '../../shared/utils/leagues'
import { get, staticGet, serverGet } from '../api'

export const queryKey = (teamId: string): QueryKey => ['teams', teamId]

const apiPath = (teamSlug: string): string => `/teams/${teamSlug}`
export const serverGetTeam = async (teamSlug: string, session: Session | null): Promise<DTO.TeamWithAffiliates> =>
    (await serverGet<DTO.TeamWithAffiliates>(apiPath(teamSlug), session)).data
export const getTeam = async (teamSlug: string): Promise<DTO.TeamWithAffiliates> =>
    (await get<DTO.TeamWithAffiliates>(apiPath(teamSlug))).data

export const useGetTeamByTeamSlug = (teamSlug: string | null | undefined): UseQueryResult<DTO.Team> =>
    // @ts-expect-error teamSlug will only get referenced here when it is defined (enabled)
    useQuery(queryKey(teamSlug), () => getTeam(teamSlug), {
        enabled: !!teamSlug,
        cacheTime: 1000 * 60 * 60 * 24,
    })

export const useGetKnicks = (): UseQueryResult<DTO.Team> => useGetTeamByTeamSlug(knicksSlug)
export const useGetWestchester = (): UseQueryResult<DTO.Team> => useGetTeamByTeamSlug(westchesterSlug)
export const useGetKnicksOrgTeam = (league: Enum.League | undefined): UseQueryResult<DTO.Team> =>
    useGetTeamByTeamSlug(getLeagueOrgTeamSlug(league))

// ONLY use in `getStaticPaths` or `getStaticProps` functions
export const staticGetTeam = async (teamSlug: string): Promise<DTO.TeamWithAffiliates> =>
    (await staticGet<DTO.TeamWithAffiliates>(apiPath(teamSlug))).data

export const getTeamByTeamId = async (teamId: string): Promise<DTO.Team> =>
    (await get<DTO.Team>(`/teams/by-team-id/${teamId}`)).data

export const useGetTeamByTeamId = (teamId: string | null | undefined): UseQueryResult<DTO.Team> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(queryKey(teamId), () => getTeamByTeamId(teamId), {
        enabled: !!teamId,
    })

export const getPrevAndNextGameInfo = async (teamId: string): Promise<DTO.PrevAndNextGameInfo> =>
    (await get<DTO.PrevAndNextGameInfo>(`/teams/${teamId}/prev-next-game-info`)).data

export const useGetPrevAndNextGameInfo = (
    teamId: string | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.PrevAndNextGameInfo>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PrevAndNextGameInfo> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(['prev-next-game-info', teamId], () => getPrevAndNextGameInfo(teamId), { enabled: !!teamId, ...options })

const useTeam = (
    teamId: string | null | undefined,
    options?: Omit<UseQueryOptions<DTO.TeamWithAffiliates>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.TeamWithAffiliates> =>
    // @ts-expect-error teamId will only get referenced here when it is defined (enabled)
    useQuery(queryKey(teamId), () => getTeam(teamId), { enabled: !!teamId, ...options })

export default useTeam
