import { format } from 'd3'
import * as math from '../../shared/utils/math'

export const { isNumber, asNumber } = math

export const safeRatio = (val: number | null | undefined, total: number | null | undefined): number | null => {
    if (!math.isNumber(val)) return null
    if (!math.isNumber(total) || total === 0) return null
    return val / total
}

export const formatNumber = (
    value: unknown,
    precision = 1,
    type: 'decimal' | 'percent' = 'decimal',
    fallback?: number,
    signed?: boolean,
    isPercentRatio = false // if true, value is already scaled to 0-100
): string | null => {
    if (!math.isNumber(value) && type === 'percent' && isPercentRatio && fallback === undefined) return null
    if (!math.isNumber(value))
        return fallback !== undefined ? formatNumber(fallback, precision, type, fallback, signed, isPercentRatio) : null
    return format(`${signed ? '+' : ''}.${precision}${type === 'percent' ? '%' : 'f'}`)(
        isPercentRatio ? value / 100 : value
    )
}

export const percentOfTotalWithIndicator = (
    val: number | undefined,
    total: number | null | undefined
): number | string | null => {
    const ratio = safeRatio(val, total)
    const percentRatio = ratio === null ? 0 : Math.round(ratio * 100)
    return percentRatio === 0 && val !== 0 && val !== undefined ? `< 1` : percentRatio
}

export const rate100 = (val: number | null | undefined, total: number | null | undefined): number | null =>
    safeRatio(val, safeRatio(total, 100))
