'use client'

import type { Dispatch, SetStateAction } from 'react'
import { useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { URLSearchParamsToObject } from '@/lib/routing/searchParams'

type UsePlayerSigningParamsReturn = {
    defaultParams: PlayerSigningParams
    filterParams: PlayerSigningParams
    setFilterParams: Dispatch<SetStateAction<PlayerSigningParams>>
}
type PlayerSigningParams = {
    contractTypes: string | (Enum.ContractType | 'E10')[]
}

const getInitFilterParams = (
    defaultParams: PlayerSigningParams,
    queryParams: Partial<PlayerSigningParams>
): PlayerSigningParams => ({
    contractTypes: (queryParams.contractTypes
        ? JSON.parse(queryParams.contractTypes as string)
        : defaultParams.contractTypes) as (Enum.ContractType | 'E10')[],
})

const defaultParams: PlayerSigningParams = { contractTypes: [] }

export default (): UsePlayerSigningParamsReturn => {
    const searchParams = useSearchParams()
    const queryParams = URLSearchParamsToObject(searchParams) as Partial<PlayerSigningParams>
    const [filterParams, setFilterParams] = useState<PlayerSigningParams>(() =>
        getInitFilterParams(defaultParams, queryParams)
    )

    // reset the filter if the defaults change
    const [prevDefaultParams, setPrevDefaultParams] = useState(defaultParams)
    if (prevDefaultParams !== defaultParams) {
        setPrevDefaultParams(defaultParams)
        setFilterParams(getInitFilterParams(defaultParams, queryParams))
    }

    return {
        defaultParams,
        filterParams,
        setFilterParams,
    }
}
