import type { UseQueryResult, UseMutationResult, QueryKey } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { get, post } from '../api'
import useToastContext from './useToastContext'
import { agentsQueryKey } from './useAgents'

export const getAgentPersonalQueryKey = (agentId: string | undefined): QueryKey => ['agent-personal', agentId]

export const queryAgentPersonal = async (agentId: string | undefined): Promise<DTO.AgentPersonal[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.AgentPersonal[]>(`/agent-personal/${agentId}`)).data

export const useQueryAgentPersonal = (agentId: string | undefined): UseQueryResult<DTO.AgentPersonal[]> =>
    useQuery(getAgentPersonalQueryKey(agentId), () => queryAgentPersonal(agentId), {
        enabled: !!agentId,
    })

export const useCreateOrUpdateAgentPersonal = (
    agentId: string | undefined
): UseMutationResult<JSONResponse<DTO.AgentPersonal>, Error, DTO.CreateAgentPersonal> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (values: DTO.CreateAgentPersonal) =>
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            post<DTO.AgentPersonal, DTO.CreateAgentPersonal>(`/agent-personal/${agentId}`, values),
        {
            onSuccess: async ({ data: agentPersonalOverride }) => {
                toastContext?.addToast({ severity: 'success', message: 'Saved Agent Info' })
                const queryKey = getAgentPersonalQueryKey(agentId)
                const prevData = queryClient.getQueryData<DTO.AgentPersonal[]>(queryKey)

                const defaultRow = prevData?.find((d) => !d.isOverride)
                if (defaultRow) {
                    queryClient.setQueryData(queryKey, [defaultRow, agentPersonalOverride])
                } else {
                    await queryClient.invalidateQueries(queryKey)
                }

                await queryClient.invalidateQueries([agentsQueryKey, agentId])
                await queryClient.invalidateQueries(['PlayerBio'])
            },
            onError: (error) => {
                let { message } = error
                if ('response' in error) {
                    message = (error.response as JSONResponse<DTO.ValidationErrors>).data.errors
                        .map((e) => e.msg)
                        .join(' | ')
                }
                toastContext?.addToast({ severity: 'error', message })
            },
        }
    )
}
