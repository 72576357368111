/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API.d";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateDraftNightEvent = /* GraphQL */ `subscription OnCreateDraftNightEvent(
  $draft: Int
  $name: String
  $value: AWSJSON
) {
  onCreateDraftNightEvent(draft: $draft, name: $name, value: $value) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDraftNightEventSubscriptionVariables,
  APITypes.OnCreateDraftNightEventSubscription
>;
export const onUpdateDraftNightEvent = /* GraphQL */ `subscription OnUpdateDraftNightEvent(
  $draft: Int
  $name: String
  $value: AWSJSON
) {
  onUpdateDraftNightEvent(draft: $draft, name: $name, value: $value) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDraftNightEventSubscriptionVariables,
  APITypes.OnUpdateDraftNightEventSubscription
>;
export const onDeleteDraftNightEvent = /* GraphQL */ `subscription OnDeleteDraftNightEvent(
  $draft: Int
  $name: String
  $value: AWSJSON
) {
  onDeleteDraftNightEvent(draft: $draft, name: $name, value: $value) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDraftNightEventSubscriptionVariables,
  APITypes.OnDeleteDraftNightEventSubscription
>;
export const onCreateDraftEntrant = /* GraphQL */ `subscription OnCreateDraftEntrant(
  $draft: Int
  $playerId: ID
  $fullName: String
  $available: Boolean
  $pick: Int
) {
  onCreateDraftEntrant(
    draft: $draft
    playerId: $playerId
    fullName: $fullName
    available: $available
    pick: $pick
  ) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDraftEntrantSubscriptionVariables,
  APITypes.OnCreateDraftEntrantSubscription
>;
export const onUpdateDraftEntrant = /* GraphQL */ `subscription OnUpdateDraftEntrant(
  $draft: Int
  $playerId: ID
  $fullName: String
  $available: Boolean
  $pick: Int
) {
  onUpdateDraftEntrant(
    draft: $draft
    playerId: $playerId
    fullName: $fullName
    available: $available
    pick: $pick
  ) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDraftEntrantSubscriptionVariables,
  APITypes.OnUpdateDraftEntrantSubscription
>;
export const onDeleteDraftEntrant = /* GraphQL */ `subscription OnDeleteDraftEntrant(
  $draft: Int
  $playerId: ID
  $fullName: String
  $available: Boolean
  $pick: Int
) {
  onDeleteDraftEntrant(
    draft: $draft
    playerId: $playerId
    fullName: $fullName
    available: $available
    pick: $pick
  ) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDraftEntrantSubscriptionVariables,
  APITypes.OnDeleteDraftEntrantSubscription
>;
export const onCreatePickOrder = /* GraphQL */ `subscription OnCreatePickOrder($draft: Int, $pick: Int, $teamId: ID) {
  onCreatePickOrder(draft: $draft, pick: $pick, teamId: $teamId) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreatePickOrderSubscriptionVariables,
  APITypes.OnCreatePickOrderSubscription
>;
export const onUpdatePickOrder = /* GraphQL */ `subscription OnUpdatePickOrder($draft: Int, $pick: Int, $teamId: ID) {
  onUpdatePickOrder(draft: $draft, pick: $pick, teamId: $teamId) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdatePickOrderSubscriptionVariables,
  APITypes.OnUpdatePickOrderSubscription
>;
export const onDeletePickOrder = /* GraphQL */ `subscription OnDeletePickOrder($draft: Int, $pick: Int, $teamId: ID) {
  onDeletePickOrder(draft: $draft, pick: $pick, teamId: $teamId) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeletePickOrderSubscriptionVariables,
  APITypes.OnDeletePickOrderSubscription
>;
