import { getDefaultLeagueProjectionType } from '../../shared/utils/leagues'
import { isAfter } from './formatters'

export { getDefaultLeagueProjectionType }

export const getDefaultStandingsSeasonType = (
    leagueName: Enum.League,
    gLeagueRegularSeasonStartDate: string
): Enum.SeasonType => {
    let seasonType: Enum.SeasonType = 'NBA_REGULAR_SEASON'
    if (leagueName !== 'NBA' && leagueName !== 'College') {
        const isRegularSeason = isAfter(gLeagueRegularSeasonStartDate)
        seasonType = isRegularSeason ? 'GLG_REGULAR_SEASON' : 'GLG_SHOWCASE'
    }
    return seasonType
}

export const gameTypeDict: Record<Enum.GameType, string> = {
    ALLSTAR: 'All-Star',
    IN_SEASON: 'NBA Cup',
    PLAYOFF: 'Playoff',
    PRESEASON: 'Preseason',
    REGULAR: 'Regular Season',
    PLAY_IN: 'Play-In',
    SHOWCASE: 'Showcase',
}
