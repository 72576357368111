import type { QueryKey, UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import type { RequestParams } from '../api'
import type { CreateScoutingSettings } from './usePosts'
import { useQueries, useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/boxscores'
const getQueryKey = (params: BoxscoreQueryParams): QueryKey => [
    'boxscores',
    { gameId: params.gameId, playerId: params.playerId },
]

export type BoxscoreQueryParams = RequestParams & {
    gameId: string
    playerId?: string
}

const queryGameBoxscore = async (params?: BoxscoreQueryParams): Promise<DTO.GameBoxscore[]> =>
    (await get<DTO.GameBoxscore[]>(apiPath, params)).data

type UseGameBoxScoresResults = Pick<
    UseQueryResult<DTO.GameBoxscore[]>,
    'data' | 'isLoading' | 'isInitialLoading' | 'isError'
>
export const useGameBoxScores = (gamePlayers: BoxscoreQueryParams[], enabled?: boolean): UseGameBoxScoresResults => {
    const queryObjs = gamePlayers.map(({ gameId, playerId }) => ({
        queryKey: getQueryKey({ gameId, playerId }),
        queryFn: () => queryGameBoxscore({ gameId, playerId }),
        enabled,
    }))
    return useQueries({
        queries: queryObjs,
    }).reduce(
        (acc: UseGameBoxScoresResults, q) => ({
            data: q.data ? [...(acc.data || []), ...q.data] : acc.data,
            isLoading: acc.isLoading || q.isLoading,
            isInitialLoading: acc.isInitialLoading || q.isInitialLoading,
            isError: acc.isError || q.isError,
        }),
        { data: undefined, isLoading: false, isError: false, isInitialLoading: false }
    )
}

export const useGameBoxScore = (
    gameId: string,
    options?: Omit<UseQueryOptions<DTO.GameBoxscore[], Error, DTO.GameBoxscore[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.GameBoxscore[]> => useQuery(getQueryKey({ gameId }), () => queryGameBoxscore({ gameId }), options)

export const useScoutingReportBoxScores = (
    settings: (DTO.ScoutingSettings | CreateScoutingSettings)[],
    tags: DTO.PostTag[],
    enabled?: boolean
): UseGameBoxScoresResults => {
    const playerIds = tags.filter((t) => t.entityType === 'player').map((t) => t.entityId)
    const gamePlayers = settings
        .filter((s) => s.type === 'Game' && s.gameId)
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        .map((s) => playerIds.map((playerId) => ({ playerId, gameId: s.gameId! })))
        .flat()
    return useGameBoxScores(gamePlayers, enabled)
}

const queryPlayersFromGameBoxscore = async (
    teamId: string,
    season: number,
    league: Enum.League
): Promise<DTO.TeamBoxscorePlayers[]> =>
    (await get<DTO.TeamBoxscorePlayers[]>(`/boxscores/${teamId}/${season}/${league}/players`)).data

export const useQueryTeamPlayersFromGameBoxscore = (
    teamId: string | null,
    season: string | null,
    league: Enum.League | null,
    options?: Omit<
        UseQueryOptions<DTO.TeamBoxscorePlayers[]>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.TeamBoxscorePlayers[]> =>
    // @ts-expect-error teamId and season will only get referenced here when they are defined (enabled)
    useQuery(['boxscore-players', teamId, season, league], () => queryPlayersFromGameBoxscore(teamId, season, league), {
        ...options,
        enabled: !!teamId && !!season && !!league && options?.enabled !== false,
    })
