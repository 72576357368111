'use client'

import type { Dispatch, SetStateAction } from 'react'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'next/navigation'
import { useConstantsContext } from '../contexts/ConstantsContext'
import { URLSearchParamsToObject } from '@/lib/routing/searchParams'
import { ageLOCCurveColorScheme } from '@/components/players/comps/AgeLOCCurves'

type UsePlayerCompsParamsReturn = {
    defaultParams: DTO.PlayerCompsFilterParams
    filterParams: DTO.PlayerCompsFilterParams
    setFilterParams: Dispatch<SetStateAction<DTO.PlayerCompsFilterParams>>
}

function activateCurves(ageLOCCurves: DTO.PlayerCompAgeLOCCurves, activeCurves: string): DTO.PlayerCompAgeLOCCurves {
    const locNames = JSON.parse(activeCurves) as Partial<DTO.LOCName>[]
    const updatedCurves = { ...ageLOCCurves }

    locNames.forEach((curve) => {
        updatedCurves[curve].active = true
    })

    return updatedCurves
}

export const getInitFilterParams = (
    defaultParams: DTO.PlayerCompsFilterParams,
    queryParams: Partial<DTO.PlayerCompsFilterParams>
): DTO.PlayerCompsFilterParams => ({
    players: (queryParams.players
        ? JSON.parse(queryParams.players as string)
        : defaultParams.players) as DTO.PlayerCompPlayer[],
    ageLOCCurves: queryParams.ageLOCCurves
        ? activateCurves(defaultParams.ageLOCCurves as DTO.PlayerCompAgeLOCCurves, queryParams.ageLOCCurves as string)
        : (defaultParams.ageLOCCurves as DTO.PlayerCompAgeLOCCurves),
    minAge: queryParams.minAge ? Number(queryParams.minAge) : defaultParams.minAge,
    maxAge: queryParams.maxAge ? Number(queryParams.maxAge) : defaultParams.maxAge,
    comparator: queryParams.comparator || defaultParams.comparator,
    minSeason: queryParams.minSeason ? Number(queryParams.minSeason) : defaultParams.minSeason,
    maxSeason: queryParams.maxSeason ? Number(queryParams.maxSeason) : defaultParams.maxSeason,
})

export default (): UsePlayerCompsParamsReturn => {
    const searchParams = useSearchParams()
    const { profileYear } = useConstantsContext()
    const queryParams = URLSearchParamsToObject(searchParams)
    const defaultParams: DTO.PlayerCompsFilterParams = useMemo<DTO.PlayerCompsFilterParams>(
        () => ({
            players: [],
            ageLOCCurves: {
                Franchise: { active: false, color: ageLOCCurveColorScheme.Franchise },
                Core: { active: false, color: ageLOCCurveColorScheme.Core },
                'Top Starter': { active: false, color: ageLOCCurveColorScheme['Top Starter'] },
                Starter: { active: false, color: ageLOCCurveColorScheme.Starter },
                'Key Reserve': { active: false, color: ageLOCCurveColorScheme['Key Reserve'] },
                Rotation: { active: false, color: ageLOCCurveColorScheme.Rotation },
                Roster: { active: false, color: ageLOCCurveColorScheme.Roster },
                'Minor League': { active: false, color: ageLOCCurveColorScheme['Minor League'] },
                'Below Minor League': { active: false, color: ageLOCCurveColorScheme['Below Minor League'] },
            },
            minAge: 17,
            maxAge: 36,
            comparator: 'Season',
            minSeason: profileYear - 2,
            maxSeason: profileYear + 4,
        }),
        [profileYear]
    )

    const [filterParams, setFilterParams] = useState<DTO.PlayerCompsFilterParams>(() =>
        getInitFilterParams(defaultParams, queryParams)
    )

    // reset the filter if the defaults change
    const [prevDefaultParams, setPrevDefaultParams] = useState(defaultParams)
    if (prevDefaultParams !== defaultParams) {
        setPrevDefaultParams(defaultParams)
        setFilterParams(getInitFilterParams(defaultParams, queryParams))
    }

    return {
        defaultParams,
        filterParams,
        setFilterParams,
    }
}
