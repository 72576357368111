/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API.d";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getDraftNightEvent = /* GraphQL */ `query GetDraftNightEvent($draft: Int!, $name: String!) {
  getDraftNightEvent(draft: $draft, name: $name) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDraftNightEventQueryVariables,
  APITypes.GetDraftNightEventQuery
>;
export const listDraftNightEvents = /* GraphQL */ `query ListDraftNightEvents(
  $filter: TableDraftNightEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listDraftNightEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      draft
      name
      value
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDraftNightEventsQueryVariables,
  APITypes.ListDraftNightEventsQuery
>;
export const getDraftEntrant = /* GraphQL */ `query GetDraftEntrant($draft: Int!, $playerId: ID!) {
  getDraftEntrant(draft: $draft, playerId: $playerId) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDraftEntrantQueryVariables,
  APITypes.GetDraftEntrantQuery
>;
export const listDraftEntrants = /* GraphQL */ `query ListDraftEntrants(
  $filter: TableDraftEntrantFilterInput
  $limit: Int
  $nextToken: String
) {
  listDraftEntrants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      draft
      playerId
      fullName
      available
      pick
      teamName
      teamId
      position
      rank
      urlSlug
      ageOnDraftDay
      collegeClass
      teamAbbr
      teamUrlSlug
      teamLogo
      twoWay
      summerLeague
      draftAndStashGLG
      draftAndStashIntl
      draftOptionNotes
      greenRoomInvite
      contractType
      contractYears
      salaryAmount
      signingTeamId
      signingTeamLogo
      signingTeamAbbr
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDraftEntrantsQueryVariables,
  APITypes.ListDraftEntrantsQuery
>;
export const getPickOrder = /* GraphQL */ `query GetPickOrder($draft: Int!, $pick: Int!) {
  getPickOrder(draft: $draft, pick: $pick) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPickOrderQueryVariables,
  APITypes.GetPickOrderQuery
>;
export const listPickOrders = /* GraphQL */ `query ListPickOrders(
  $filter: TablePickOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listPickOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      draft
      pick
      teamId
      teamName
      teamLogo
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPickOrdersQueryVariables,
  APITypes.ListPickOrdersQuery
>;
