import type { ProfileSectionSummary } from '@/components/profile/ProfileSection'
import { useMemo } from 'react'
import { formatNumber } from '../utils/math'

const useShootingSummary = (
    threePtModel: DTO.ThreePointModel | '' | undefined,
    isLoadingThreePtModel: boolean
): ProfileSectionSummary[] | undefined =>
    useMemo(() => {
        if (threePtModel !== undefined) {
            return [
                {
                    label: 'Proj. 3FG%',
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    value: threePtModel ? `${formatNumber(threePtModel.proj3ptPct, 1, 'percent')}` : undefined,
                    isLoading: isLoadingThreePtModel,
                },
                {
                    label: 'Proj. 3FGM',
                    value: threePtModel ? formatNumber(threePtModel.proj3ptMks) : undefined,
                    isLoading: isLoadingThreePtModel,
                },
                {
                    label: 'Proj. Type',
                    value: threePtModel ? threePtModel.projType : undefined,
                    isLoading: isLoadingThreePtModel,
                },
            ]
        }
        return undefined
    }, [threePtModel, isLoadingThreePtModel])

export default useShootingSummary
