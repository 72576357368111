import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { Session } from 'next-auth'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { get, post, put, serverGet } from '../api'

type ContentPermissionParams = {
    displayName: string
    status: string
}

type ContentPermissionUpdateParams = ContentPermissionParams & { slug: string }

type QueryKey = ['contentPermissions']

export const contentPermissionsQueryKey: QueryKey = ['contentPermissions']

const apiPath = '/content-permissions'

export const serverQueryContentPermissions = async (session: Session | null): Promise<DTO.ContentPermission> =>
    (await serverGet<DTO.ContentPermission>(apiPath, session)).data

export const queryContentPermissions = async (): Promise<DTO.ContentPermission> =>
    (await get<DTO.ContentPermission>(apiPath)).data

export const useQueryContentPermissions = ({
    queryKey,
}: {
    queryKey: QueryKey
}): UseQueryResult<DTO.ContentPermission[]> => useQuery(queryKey, () => queryContentPermissions())

export const useCreateContentPermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.ContentPermission>, Error, ContentPermissionParams> => {
    const queryClient = useQueryClient()
    return useMutation(
        (newContentPermission: ContentPermissionParams) =>
            post<DTO.ContentPermission, ContentPermissionParams>(apiPath, newContentPermission),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}

export const useUpdateContentPermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.ContentPermission>, Error, ContentPermissionUpdateParams> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedContentPermission: ContentPermissionUpdateParams) =>
            put<DTO.ContentPermission, ContentPermissionUpdateParams>(
                `${apiPath}/${updatedContentPermission.slug}`,
                updatedContentPermission
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}
