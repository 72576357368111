import type { JSONResponse } from '../api'
import type { UseMutationResult, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { Except } from 'type-fest'
import type { Session } from 'next-auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { get, post, serverGet } from '../api'
import { useConstantsContext } from '../contexts/ConstantsContext'

const apiPath = '/player-personal'

export type PlayerPersonalQueryParams = {
    playerId: string | undefined
    options?: Omit<
        UseQueryOptions<DTO.PlayerPersonal | undefined>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}

export type CreateOrUpdatePlayerPersonalValues = Except<
    DTO.CreatePlayerPersonal,
    'playerId' | 'isOverride' | 'updatedBy'
>

export const serverQueryPlayerPersonal = async (
    playerId: string,
    session: Session | null
): Promise<DTO.PlayerPersonal[]> => (await serverGet<DTO.PlayerPersonal[]>(`${apiPath}/${playerId}`, session)).data

export const queryPlayerPersonal = async (playerId: string): Promise<DTO.PlayerPersonal[]> =>
    (await get<DTO.PlayerPersonal[]>(`${apiPath}/${playerId}`)).data

export const useQueryPlayerPersonal = ({
    playerId,
    options,
}: PlayerPersonalQueryParams): UseQueryResult<DTO.PlayerPersonal[]> =>
    // @ts-expect-error playerId will only get referenced here when it is defined (enabled)
    useQuery(['playerPersonal', playerId], () => queryPlayerPersonal(playerId), {
        enabled: !!playerId && options?.enabled !== false,
    })

export const useCreateOrUpdatePlayerPersonal = (
    playerId: string,
    playerSlug: string
): UseMutationResult<JSONResponse<CreateOrUpdatePlayerPersonalValues>, Error, CreateOrUpdatePlayerPersonalValues> => {
    const queryClient = useQueryClient()
    const { salaryYear } = useConstantsContext()
    return useMutation(
        (values: CreateOrUpdatePlayerPersonalValues) =>
            post<CreateOrUpdatePlayerPersonalValues, CreateOrUpdatePlayerPersonalValues>(
                `${apiPath}/${playerId}`,
                values
            ),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['playerPersonal', playerId])
                await queryClient.invalidateQueries(['players', playerSlug])
                await queryClient.invalidateQueries(['PlayerBio', playerId, salaryYear])
            },
        }
    )
}
