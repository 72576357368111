import type { Dayjs, ConfigType, UnitType, ManipulateType, OptionType } from 'dayjs'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'
import tz from 'dayjs/plugin/timezone'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(tz)
dayjs.extend(advancedFormat)
dayjs.extend(isSameOrAfter)
dayjs.tz.guess()

export const dateInTimezone = (date: ConfigType, timezone = 'America/New_York'): Dayjs => dayjs(date).tz(timezone)

export const formatDateString = (
    value: ConfigType,
    format: string | undefined, // undefined "format" defaults to ISO format
    mode: 'utc' | 'local' | 'eastern'
): string => {
    if (mode === 'eastern') return dateInTimezone(value).format(format)
    if (mode === 'local') return dayjs(value).format(format)
    return dayjs.utc(value).format(format)
}

// eslint-disable-next-line arrow-body-style
export const utcDate = (): Date => {
    /* if (value) {
        return dayjs.utc(value).toDate()
    } */
    return dayjs.utc().toDate()
}

export const pastDate = (value: number, unit: ManipulateType | undefined): Date =>
    dayjs().subtract(value, unit).toDate()

export const diff = (date: ConfigType, unit: UnitType, asOfDate?: ConfigType, float?: boolean): number =>
    dayjs(asOfDate).diff(date, unit, float)

export const ageAsOfDate = (birthDate: ConfigType, dateOrSeason: number | string | Date): number => {
    let asOfDate: ConfigType = dateOrSeason
    if (typeof dateOrSeason === 'number') asOfDate = `${dateOrSeason + 1}-01-01`

    return Math.round(diff(birthDate, 'days', asOfDate) / 365.25)
}

export const isSameOrAfterDate = (value1: ConfigType, value2: ConfigType): boolean =>
    dayjs(value1).isSameOrAfter(value2, 'day')
export const isSameOrAfterToday = (value: ConfigType): boolean => isSameOrAfterDate(value, undefined)
export const isTodaySameOrAfter = (value: ConfigType): boolean => isSameOrAfterDate(undefined, value)
export const isAfter = (value: ConfigType): boolean => dayjs().isAfter(dayjs(value))
export const isBefore = (value: ConfigType): boolean => dayjs().isBefore(dayjs(value))

export const isToday = (value: ConfigType): boolean => dayjs(value).isSame(undefined, 'day')

export const addDate = (date: ConfigType, value: number, unit: ManipulateType): Dayjs => dayjs(date).add(value, unit)

export const fromNow = (value: ConfigType): string => dayjs(value).fromNow()

export const parse = (dateString: string, format: OptionType = 'MM/DD/YYYY'): Dayjs => dayjs(dateString, format)

export const formatSeason = (season: number | string, isShort = false): string => {
    if (typeof season === 'string' && season.includes('-')) return season

    const seasonNum = Number(season)
    return `${isShort ? seasonNum.toString().slice(-2) : seasonNum}-${(seasonNum + 1).toString().slice(-2)}`
}
export const seasonAsNumber = (season: number | string): number => {
    if (typeof season === 'number') return season

    let seasonStr = season.toString()
    if (seasonStr.includes('-')) seasonStr = seasonStr.split('-')[0]
    return Number(seasonStr)
}
