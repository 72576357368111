import type { UseQueryResult } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import type { RequestParams } from '../api'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

export type AgencyAgentsParams = RequestParams & {
    slug: string
    canViewContractGrades: boolean
}

export const serverGetAgencyBySlug = async (slug: string, session: Session | null): Promise<DTO.Agency> =>
    (await serverGet<DTO.Agency>(`/agencies/${slug}`, session)).data

export const getAgencyBySlug = async (slug: string): Promise<DTO.Agency> =>
    (await get<DTO.Agency>(`/agencies/${slug}`)).data

export const useGetAgencyBySlug = (slug: string): UseQueryResult<DTO.Agency> =>
    useQuery<DTO.Agency>(['agencies', slug], () => getAgencyBySlug(slug))

export const getAgencyAgentsBySlug = async (params: AgencyAgentsParams): Promise<DTO.AgencySummaryAgent[]> =>
    (await get<DTO.AgencySummaryAgent[]>(`/agencies/${params.slug}/summary`, params)).data

export const useGetAgencyAgentsBySlug = (params: AgencyAgentsParams): UseQueryResult<DTO.AgencySummaryAgent[]> =>
    useQuery<DTO.AgencySummaryAgent[]>(['agencies-summary', params.slug], () => getAgencyAgentsBySlug(params))
