'use client'

import { useState, useEffect } from 'react'

function useDebounce<T>(value: T, delay?: number): T
function useDebounce<T>(value: T, delay: number, progressInterval: number): [T, number]
function useDebounce<T>(value: T, delay?: number, progressInterval?: number): T | [T, number] {
    const [debouncedValue, setDebouncedValue] = useState(value)
    const [timeLeft, setTimeLeft] = useState(0)
    const trimmedValue = (typeof value === 'string' ? value.trim() : value) as T

    const delayTicks = delay === undefined ? 600 : delay * 1000
    useEffect(() => {
        const debounceTimeout = setTimeout(() => {
            setDebouncedValue(trimmedValue)
        }, delayTicks)

        let timeLeftInterval: NodeJS.Timeout | undefined
        if (progressInterval !== undefined) {
            setTimeLeft(delayTicks)

            const timerEnd = Date.now() + delayTicks
            timeLeftInterval = setInterval(() => {
                const timeRemaining = Math.max(timerEnd - Date.now(), 0)
                setTimeLeft(timeRemaining)
                if (!timeRemaining) clearInterval(timeLeftInterval)
            }, progressInterval * 1000)
        }

        // Cancel the timeout if value changes (also on delay change or unmount)
        return () => {
            clearTimeout(debounceTimeout)
            clearInterval(timeLeftInterval)
        }
    }, [trimmedValue, delayTicks, progressInterval])

    return progressInterval === undefined ? debouncedValue : [debouncedValue, timeLeft]
}

export default useDebounce
