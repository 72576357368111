'use client'

import type { ReducerState, ReducerAction, Dispatch } from 'react'
import type { Order } from '../../components/Table/sorting'
import { useReducer } from 'react'

interface CustomSortState<T> {
    column?: T
    order?: Order
}
interface CustomSortAction<T> extends Pick<CustomSortState<T>, 'column'> {
    type: 'set' | 'clear'
}

interface SortToggleReducer<T> {
    (state: CustomSortState<T>, action: CustomSortAction<T>): CustomSortState<T>
}
const reducer = <T>(state: CustomSortState<T>, action: CustomSortAction<T>): CustomSortState<T> => {
    switch (action.type) {
        case 'set': {
            const { column: stateColumn, order: stateOrder } = state
            const { column: actionColumn } = action

            let sortOrder: CustomSortState<T>['order'] = 'asc'
            if (stateColumn === actionColumn) {
                if (stateOrder === 'asc') sortOrder = 'desc'
                else if (stateOrder === 'desc') sortOrder = undefined
            }

            return sortOrder
                ? {
                      column: actionColumn,
                      order: sortOrder,
                  }
                : {}
        }
        default:
            return {}
    }
}

export type SortToggleState<T extends string = string> = ReducerState<SortToggleReducer<T>>
export type SortToggleAction<T extends string = string> = Dispatch<ReducerAction<SortToggleReducer<T>>>
const useSortToggle = <T extends string>(
    initialSort: CustomSortState<T> = {}
): [SortToggleState<T>, SortToggleAction<T>] => useReducer(reducer as SortToggleReducer<T>, initialSort)

export default useSortToggle
