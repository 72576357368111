import type { UseQueryResult } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

const getPlayerLeaderboard = async (filters: DTO.LeaderboardFilterParams): Promise<DTO.Leaderboard[]> =>
    (await get<DTO.Leaderboard[]>(`/leaderboard`, filters)).data

export const usePlayerLeaderboard = (filters: DTO.LeaderboardFilterParams): UseQueryResult<DTO.Leaderboard[]> =>
    useQuery(['player-leaderboard', filters], () => getPlayerLeaderboard(filters))

export const serverGetPlayerLeaderboard = async (
    session: Session | null,
    filters: DTO.LeaderboardFilterParams
): Promise<DTO.Leaderboard[]> => (await serverGet<DTO.Leaderboard[]>(`/leaderboard`, session, filters)).data
