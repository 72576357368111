import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

type OEmbed = {
    type: string
    version: string
    provider_name: string
    provider_url: string
    title: string
    author_name: string
    author_url: string
    is_plus: string
    account_type: string
    html: string
    width: number
    height: number
    duration: number
    description: string
    thumbnail_url: string
    thumbnail_width: number
    thumbnail_height: number
    thumbnail_url_with_play_button: string
    upload_date: string
    domain_status_code: number
    video_id: number
    uri: string
}

export default (
    vimeoVideoId: number | undefined,
    startTime: number | null,
    responsive = true
): UseQueryResult<OEmbed> =>
    useQuery(
        ['vimeo-video-embed', vimeoVideoId, startTime],
        async () =>
            (
                await get<OEmbed>(
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions -- vimeoVideoId is always a number
                    `https://vimeo.com/api/oembed.json?url=https://vimeo.com/${vimeoVideoId}&responsive=${responsive}&byline=0&portrait=0&title=0&start_time=${
                        startTime || 0
                    }`,
                    undefined,
                    undefined,
                    '',
                    true
                )
            ).data,
        { enabled: !!vimeoVideoId }
    )
