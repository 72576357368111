import type { UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const queryPlayerShots = async (playerId: string | undefined, params: DTO.ShotFilters): Promise<DTO.GameShot[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.GameShot[]>(`/profile/${playerId}/shots`, params)).data

const usePlayerShots = (
    playerId: string | undefined,
    filters: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.GameShot[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.GameShot[]> =>
    useQuery<DTO.GameShot[]>(['shots', 'player-shots', playerId, filters], () => queryPlayerShots(playerId, filters), {
        ...options,
        enabled: !!playerId && options?.enabled !== false,
    })

const queryShotAvgs = async (
    secondaryPosition: DTO.PlayerSecondaryPosition | undefined,
    params: DTO.ShotFilters
): Promise<DTO.ShotAvgs[]> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.ShotAvgs[]>(`/profile/${secondaryPosition}/shot-avgs`, params)).data

export const useShotsAvgs = (
    secondaryPosition: DTO.PlayerSecondaryPosition | undefined,
    filters: DTO.ShotFilters,
    options?: Omit<UseQueryOptions<DTO.ShotAvgs[]>, 'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'>
): UseQueryResult<DTO.ShotAvgs[]> =>
    useQuery<DTO.ShotAvgs[]>(
        ['shots', 'shots-avgs', secondaryPosition, filters],
        () => queryShotAvgs(secondaryPosition, filters),
        {
            ...options,
            enabled: !!secondaryPosition && options?.enabled !== false,
        }
    )

export default usePlayerShots
