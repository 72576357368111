import type { UseQueryResult, UseMutationResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import type { JSONResponse } from '../api'
import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import { useSession } from 'next-auth/react'
import { useMemo } from 'react'
import { get, put, remove, post, serverGet } from '../api'
import useToastContext from './useToastContext'

export type ErrorResp = { warning?: string }

export const serverGetDepthChartList = async (
    session: Session | null,
    withDesignation?: boolean
): Promise<DTO.DepthChart[]> => (await serverGet<DTO.DepthChart[]>(`/depth-charts`, session, { withDesignation })).data

export const getDepthChartList = async (withDesignation?: boolean): Promise<DTO.DepthChart[]> =>
    (await get<DTO.DepthChart[]>(`/depth-charts`, { withDesignation })).data

export const useDepthChartList = (
    withDesignation?: boolean,
    options?: Omit<UseQueryOptions<DTO.DepthChart[], Error, DTO.DepthChart[], QueryKey>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.DepthChart[]> => {
    const { data: session } = useSession()
    return useQuery<DTO.DepthChart[], Error, DTO.DepthChart[], QueryKey>(
        ['depth-charts', withDesignation],
        () => getDepthChartList(withDesignation),
        { ...options, enabled: !!session?.roles.featurePermissions['depth-charts'] && options?.enabled !== false }
    )
}

export const serverGetDepthChart = (depthChartId: string, session: Session | null): Promise<DTO.DepthChartResp> =>
    serverGet<DTO.DepthChartResp>(`/depth-charts/${depthChartId}`, session).then((resp) => resp.data)

export const serverGetDepthChartTeams = async (
    league: Enum.League,
    session: Session | null
): Promise<DTO.DepthChartTeam[]> =>
    (await serverGet<DTO.DepthChartTeam[]>(`/depth-charts/teams/${league}`, session)).data

export const getDepthChart = (depthChartId: string): Promise<DTO.DepthChartResp> =>
    get<DTO.DepthChartResp>(`/depth-charts/${depthChartId}`).then((resp) => resp.data)

export const useDepthChart = (
    depthChartId: string,
    options?: Omit<
        UseQueryOptions<DTO.DepthChartResp, Error, DTO.DepthChartResp, ['depth-charts', string]>,
        'queryKey' | 'queryFn'
    >
): UseQueryResult<DTO.DepthChartResp> =>
    useQuery(['depth-charts', depthChartId.toLowerCase()], () => getDepthChart(depthChartId), {
        ...options,
        enabled: !!depthChartId && options?.enabled !== false,
    })

export const useValidationPlayers = (league: Enum.League): UseQueryResult<DTO.ValidationPlayer[]> =>
    useQuery(
        ['depth-charts', 'validation-players', league],
        async () => (await get<DTO.ValidationPlayer[]>(`/depth-charts/validate-players/${league}`)).data
    )

const getDepthChartTeams = async (league: Enum.League) =>
    (await get<DTO.DepthChartTeam[]>(`/depth-charts/teams/${league}`)).data

export const useDepthChartTeams = (league: Enum.League | undefined): UseQueryResult<DTO.DepthChartTeam[]> =>
    // @ts-expect-error league will only get referenced here when it is defined (enabled)
    useQuery(['depth-charts', league], () => getDepthChartTeams(league), {
        enabled: !!league,
    })

export const useRevokedDraftPicks = (draft: string | undefined): UseQueryResult<DTO.RevokedDraftPicks[]> =>
    useQuery(
        ['revoked-draft-picks', draft],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.RevokedDraftPicks[]>(`/depth-charts/revoked-draft-picks/${draft}`)).data,
        {
            enabled: !!draft,
        }
    )

export const useAddDepthChartItem = (): UseMutationResult<JSONResponse<ErrorResp>, Error, DTO.SaveDepthChartItem> => {
    const queryClient = useQueryClient()
    return useMutation(
        (depthChartItem: DTO.SaveDepthChartItem) =>
            post<ErrorResp, DTO.SaveDepthChartItem>(
                `/depth-charts/${depthChartItem.depthChartId}/items`,
                depthChartItem
            ),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['depth-charts'])
            },
        }
    )
}

export const useDeleteDepthChartItem = (): UseMutationResult<JSONResponse, Error, DTO.DepthChartItemToEdit> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()

    return useMutation(
        (item: DTO.DepthChartItemToEdit) =>
            remove(`/depth-charts/${item.depthChartId}/items/${item.teamId}/${item.position}/${item.rank}`),
        {
            onError: (_err, deletedItem, context) => {
                toastContext?.addToast({
                    severity: 'error',
                    message: 'Delete Failed',
                })
                queryClient.setQueryData(
                    ['depth-charts', deletedItem.depthChartId.toLowerCase()],
                    context?.previousDepthChartData
                )
            },
            onSuccess: () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Item Deleted',
                })
            },
            onMutate: (deletedItem: DTO.DepthChartItemToEdit) => {
                const previousDepthChartData: DTO.DepthChartResp | undefined = queryClient.getQueryData([
                    'depth-charts',
                    deletedItem.depthChartId.toLowerCase(),
                ])
                if (previousDepthChartData) {
                    const updatedDepthChartData: DTO.DepthChartResp = {
                        ...previousDepthChartData,
                        items: previousDepthChartData.items.filter(
                            (d) =>
                                d.depthChartId !== deletedItem.depthChartId ||
                                d.position !== deletedItem.position ||
                                d.rank !== deletedItem.rank ||
                                d.teamId !== deletedItem.teamId
                        ),
                    }
                    queryClient.setQueryData(
                        ['depth-charts', deletedItem.depthChartId.toLowerCase()],
                        updatedDepthChartData
                    )
                }
                return { previousDepthChartData }
            },
        }
    )
}

export const useCreateDepthChart = (): UseMutationResult<JSONResponse<ErrorResp>, Error, DTO.SaveDepthChart> => {
    const queryClient = useQueryClient()
    return useMutation(
        (depthChart: DTO.SaveDepthChart) =>
            post<ErrorResp, DTO.SaveDepthChart>(`/depth-charts/${depthChart.depthChartId}`, depthChart),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['depth-charts'])
            },
        }
    )
}

export const useEditDepthChart = (): UseMutationResult<JSONResponse<ErrorResp>, Error, DTO.SaveDepthChart> => {
    const queryClient = useQueryClient()
    return useMutation(
        (depthChart: DTO.SaveDepthChart) =>
            put<ErrorResp, DTO.SaveDepthChart>(`/depth-charts/${depthChart.depthChartId}`, depthChart),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['depth-charts'])
            },
        }
    )
}

const isErrorResp = (data: DTO.DepthChartItem[] | ErrorResp): data is ErrorResp => !!(data as ErrorResp).warning
export const useEditDepthChartItem = (): UseMutationResult<
    JSONResponse<DTO.DepthChartItem[] | ErrorResp>,
    Error,
    DTO.SaveDepthChartItem,
    { previousDepthChartData: DTO.DepthChartResp | undefined }
> => {
    const queryClient = useQueryClient()
    return useMutation(
        (depthChartItem: DTO.SaveDepthChartItem) => {
            const { depthChartId, teamId, position, rank } = depthChartItem
            return put<DTO.DepthChartItem[] | ErrorResp, DTO.SaveDepthChartItem>(
                `/depth-charts/${depthChartId}/items/${teamId}/${position}/${rank}`,
                depthChartItem
            )
        },
        {
            onMutate: (updatedItem) => {
                const { depthChartId, teamId, position, rank } = updatedItem

                const previousDepthChartData: DTO.DepthChartResp | undefined = queryClient.getQueryData([
                    'depth-charts',
                    depthChartId.toLowerCase(),
                ])
                if (previousDepthChartData) {
                    const updatedDepthChartData: DTO.DepthChartResp = {
                        ...previousDepthChartData,
                        items: [
                            ...previousDepthChartData.items.filter(
                                (i) => i.teamId !== teamId || i.position !== position || i.rank !== rank
                            ),
                            updatedItem as DTO.DepthChartItem,
                        ],
                    }
                    queryClient.setQueryData(['depth-charts', depthChartId.toLowerCase()], updatedDepthChartData)
                }

                return { previousDepthChartData }
            },
            onSuccess: async ({ data: newItems }, { depthChartId }, context) => {
                if (isErrorResp(newItems) || !context?.previousDepthChartData) {
                    await queryClient.invalidateQueries(['depth-charts', depthChartId.toLowerCase()])
                } else {
                    const updatedDepthChartData: DTO.DepthChartResp = {
                        ...context.previousDepthChartData,
                        items: newItems,
                    }
                    queryClient.setQueryData(['depth-charts', depthChartId.toLowerCase()], updatedDepthChartData)
                }
            },
            onError: (_err, { depthChartId }, context) => {
                queryClient.setQueryData(['depth-charts', depthChartId.toLowerCase()], context?.previousDepthChartData)
            },
        }
    )
}

export const useEditDepthChartItems = (): UseMutationResult<JSONResponse<ErrorResp>, Error, DTO.DepthChartItem[]> => {
    const queryClient = useQueryClient()
    return useMutation(
        (depthChartItems: DTO.DepthChartItem[]) =>
            put<ErrorResp, DTO.DepthChartItem[]>(
                `/depth-charts/${depthChartItems[0].depthChartId}/items`,
                depthChartItems
            ),
        {
            onMutate: (newItems: DTO.DepthChartItem[]) => {
                const depthChartId = newItems[0].depthChartId.toLowerCase()
                const previousDepthChartData: DTO.DepthChartResp | undefined = queryClient.getQueryData([
                    'depth-charts',
                    depthChartId,
                ])
                if (previousDepthChartData) {
                    const teamPositions = new Set(newItems.map((i) => `${i.teamId} ${i.position}`))
                    const otherItems = previousDepthChartData.items.filter(
                        (d) => !teamPositions.has(`${d.teamId} ${d.position}`)
                    )

                    const updatedDepthChartData: DTO.DepthChartResp = {
                        ...previousDepthChartData,
                        items: [...newItems, ...otherItems].filter((d) => !!d.depthChartId),
                    }
                    queryClient.setQueryData(['depth-charts', depthChartId], updatedDepthChartData)
                }
                return { previousDepthChartData }
            },
            onError: (_err, newItems, context) => {
                queryClient.setQueryData(
                    ['depth-charts', newItems[0].depthChartId.toLowerCase()],
                    context?.previousDepthChartData
                )
            },
        }
    )
}

export const useUpdateDepthChartStatus = (): UseMutationResult<
    JSONResponse<DTO.DepthChartStatusUpdate>,
    Error,
    DTO.DepthChartStatusUpdate
> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (statusUpdate: DTO.DepthChartStatusUpdate) =>
            put<DTO.DepthChartStatusUpdate, DTO.DepthChartStatusUpdate>(
                `/depth-charts/${statusUpdate.depthChartId}/status`,
                statusUpdate
            ),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['depth-charts'])
            },
            onSuccess: () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Status Update Successful',
                })
            },
            onError: () => {
                toastContext?.addToast({
                    severity: 'error',
                    message: 'Status Update Failed',
                })
            },
        }
    )
}

export const useDeleteDepthChart = (): UseMutationResult<JSONResponse, Error, DTO.DepthChartStatusUpdate> => {
    const queryClient = useQueryClient()
    const toastContext = useToastContext()
    return useMutation(
        (statusUpdate: DTO.DepthChartStatusUpdate) =>
            remove(`/depth-charts/${statusUpdate.depthChartId}`, statusUpdate),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['depth-charts'])
            },
            onSuccess: () => {
                toastContext?.addToast({
                    severity: 'success',
                    message: 'Depth Chart Deleted',
                })
            },
            onError: () => {
                toastContext?.addToast({
                    severity: 'error',
                    message: 'Status Update Failed',
                })
            },
        }
    )
}

export const getDepthChartItem = async (
    depthChartId: string,
    depthChartItemId: string
): Promise<DTO.DepthChartItemModel | null | ''> =>
    (
        await get<Promise<DTO.DepthChartItemModel | null | ''>>(
            `/depth-charts/${depthChartId}/players/${depthChartItemId}`
        )
    ).data

export const usePlayerAbbrs = (depthChartItems: DTO.DepthChartItem[]): Record<string, string> =>
    useMemo<Record<string, string>>(() => {
        const getAbbr = (name: string, fLength = 1) => {
            const [firstName, ...lastName] = name.split(' ')
            return `${firstName.slice(0, fLength)}. ${lastName.join(' ')}`
        }

        const [playerAbbrs, playerAbbrCounts] = depthChartItems.reduce(
            (acc: [Record<string, { name: string; abbr: string }>, Record<string, number>], i) => {
                if (i.typePlayerId === null || i.playerName === null) return acc
                const [abbrs, counts] = acc
                const playerAbbr = getAbbr(i.playerName)
                abbrs[i.typePlayerId] = { name: i.playerName, abbr: playerAbbr }
                counts[playerAbbr] = (counts[playerAbbr] || 0) + 1
                return acc
            },
            [{}, {}]
        )

        return Object.entries(playerAbbrs).reduce((acc: Record<string, string>, [id, { name, abbr }]) => {
            const hasDuplicate = playerAbbrCounts[abbr] > 1
            acc[id] = hasDuplicate ? getAbbr(name, 3) : abbr
            return acc
        }, {})
    }, [depthChartItems])
