import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

export const getSkillsForPastThirtyDays = async (
    playerId: string,
    scoutEntityId: string
): Promise<DTO.ScoutingSkills | ''> =>
    (await get<DTO.ScoutingSkills>(`/scouting-skills/${playerId}/${scoutEntityId}/past-thirty-days`)).data

export const useGetSkillsForPastThirtyDays = ({
    playerId,
    scoutEntityId,
    options,
}: {
    playerId: string | null
    scoutEntityId: string
    options: Omit<
        UseQueryOptions<DTO.ScoutingSkills | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
}): UseQueryResult<DTO.ScoutingSkills | ''> =>
    useQuery<DTO.ScoutingSkills | ''>(
        ['scouting-skills', playerId, scoutEntityId],
        // @ts-expect-error playerId will only get referenced here when it is defined (enabled)
        () => getSkillsForPastThirtyDays(playerId, scoutEntityId),
        { enabled: !!playerId, ...options }
    )
