/* eslint-disable import/order */
import type { UseQueryResult, UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse, RequestParams } from '../api'
import type { Session } from 'next-auth'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, post, put, serverGet } from '../api'

export const scoutingEventsQueryKey = 'scouting-events' as const
const apiPath = '/scouting-events' as const

export type ScoutingEventQueryParams = RequestParams & {
    level?: Enum.ReportLevel
    eventId?: string
}

const queryScoutingEvents = async (params?: ScoutingEventQueryParams): Promise<DTO.ScoutingEvent[]> =>
    (await get<DTO.ScoutingEvent[]>(apiPath, params)).data

export const useScoutingEvents = (params?: ScoutingEventQueryParams): UseQueryResult<DTO.ScoutingEvent[]> =>
    useQuery<DTO.ScoutingEvent[]>([scoutingEventsQueryKey, params], () => queryScoutingEvents(params))

export const serverQueryScoutingEventsById = async (
    eventId: string,
    session: Session | null
): Promise<DTO.ScoutingEvent> => (await serverGet<DTO.ScoutingEvent>(`${apiPath}/${eventId})`, session)).data

export const queryScoutingEventsById = async (eventId: string): Promise<DTO.ScoutingEvent> =>
    (await get<DTO.ScoutingEvent>(`${apiPath}/${eventId})`)).data

export const useScoutingEventById = (eventId: string | undefined): UseQueryResult<DTO.ScoutingEvent> =>
    // @ts-expect-error eventId will only get referenced here when it is defined (enabled)
    useQuery<DTO.ScoutingEvent>([scoutingEventsQueryKey, eventId], () => queryScoutingEventsById(eventId), {
        enabled: !!eventId,
    })

export const useUpdateScoutingEvent = (): UseMutationResult<JSONResponse, Error, DTO.ScoutingEvent> => {
    const queryClient = useQueryClient()
    return useMutation((event: DTO.ScoutingEvent) => put(`${apiPath}/${event.scoutingEventId}`, event), {
        onSettled: () => queryClient.invalidateQueries([scoutingEventsQueryKey]),
    })
}

export const useCreateScoutingEvent = (): UseMutationResult<JSONResponse, Error, DTO.ScoutingEvent> => {
    const queryClient = useQueryClient()
    return useMutation((event: DTO.ScoutingEvent) => post(apiPath, event), {
        onSettled: () => queryClient.invalidateQueries([scoutingEventsQueryKey]),
    })
}
