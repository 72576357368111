import type { UseMutationResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useQueryClient, useMutation } from '@tanstack/react-query'
import { post, remove } from '../api'

type UserGroupFeaturePermissionParams = {
    userGroup: string
    featurePermission: string
}

type QueryKey = ['userGroupsWithPermissions']

export const userGroupFeaturePermissionsQueryKey: QueryKey = ['userGroupsWithPermissions']

const apiPath = '/user-groups/feature-permissions'

export const useCreateUserGroupFeaturePermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse, Error, UserGroupFeaturePermissionParams> => {
    const queryClient = useQueryClient()
    return useMutation(
        (newUserGroupFeaturePermission: UserGroupFeaturePermissionParams) =>
            post<DTO.UserGroupFeaturePermission, UserGroupFeaturePermissionParams>(
                apiPath,
                newUserGroupFeaturePermission
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}

export const useDeleteUserGroupFeaturePermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse, Error, string> => {
    const queryClient = useQueryClient()
    return useMutation(
        (userGroupFeaturePermissionSlug: string) => remove(`${apiPath}/${userGroupFeaturePermissionSlug}`),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}
