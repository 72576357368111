import type { UseMutationResult, UseQueryResult, UseQueryOptions } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query'
import { get, post } from '../api'

const getScoutingSummary = async (playerId: string | undefined): Promise<DTO.ScoutingSummary | ''> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await get<DTO.ScoutingSummary | ''>(`/profile/${playerId}/scouting-summary`)).data

export const useGetScoutingSummary = (
    playerId: string | undefined,
    options?: Omit<
        UseQueryOptions<DTO.IntensityGrade | ''>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ScoutingSummary | ''> =>
    useQuery<DTO.ScoutingSummary | ''>(['scouting-summary', playerId], () => getScoutingSummary(playerId), {
        enabled: !!playerId && options?.enabled !== false,
    })

export const useCreateScoutingSummary = (
    playerId: string | undefined
): UseMutationResult<JSONResponse, Error, Params.ScoutingSummary | ''> => {
    const queryClient = useQueryClient()
    return useMutation(
        (scoutingSummary: Params.ScoutingSummary | '') =>
            post<undefined, Params.ScoutingSummary | ''>('/profile/scouting-summary/create', scoutingSummary),
        {
            onSettled: async () => {
                await queryClient.invalidateQueries(['scouting-summary', playerId])
            },
        }
    )
}
