import type { UseQueryOptions, UseQueryResult } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

type EntityParams = {
    teamId?: string | null
    entityType?: DTO.SearchEntityType
}

export const serverGetEntityByUrlSlug = async (
    entityType: DTO.SearchEntityType,
    urlSlug: string,
    session: Session | null
): Promise<DTO.Entity> => (await serverGet<DTO.Entity>(`/entities/${entityType}/${urlSlug}`, session)).data

export const getEntityByUrlSlug = async (entityType: DTO.SearchEntityType, urlSlug: string): Promise<DTO.Entity> =>
    (await get<DTO.Entity>(`/entities/${entityType}/${urlSlug}`)).data

export const useGetEntityByUrlSlug = (
    entityType: DTO.SearchEntityType,
    urlSlug: string | undefined | null,
    options?: Omit<UseQueryOptions<DTO.Entity>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Entity> =>
    // @ts-expect-error urlSlug will only get referenced here when it is defined (enabled)
    useQuery<DTO.Entity>(['entities', entityType, urlSlug], () => getEntityByUrlSlug(entityType, urlSlug), {
        enabled: !!urlSlug && options?.enabled !== false,
        ...options,
    })

const queryEntities = async (params: EntityParams) => (await get<DTO.Entity[]>('/entities', params)).data

export const useQueryEntities = (
    params: EntityParams,
    options?: Omit<UseQueryOptions<DTO.Entity[]>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Entity[]> =>
    useQuery<DTO.Entity[]>(['entities', params], () => queryEntities(params), {
        ...options,
        enabled: options?.enabled !== false,
    })
