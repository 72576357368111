import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '../api'
import type { PlayerAgentFilterParams } from '@/components/player-agents/PlayerAgentFilters'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { get, put } from '../api'

type PlayerAgentHistoryQueryParams = {
    agentId?: string
    agencyId?: string
    playerId?: string
}

const queryPlayerAgentHistories = async ({
    agentId,
    agencyId,
    playerId,
}: PlayerAgentHistoryQueryParams): Promise<DTO.PlayerAgentHistory[]> =>
    (await get<DTO.PlayerAgentHistory[]>('/player-agent-histories', { agentId, agencyId, playerId })).data

export const useQueryPlayerAgentHistories = ({
    agentId,
    agencyId,
    playerId,
}: PlayerAgentHistoryQueryParams): UseQueryResult<DTO.PlayerAgentHistory[]> =>
    useQuery(['playerAgentHistories', agentId, agencyId, playerId], () =>
        queryPlayerAgentHistories({ agentId, agencyId, playerId })
    )

export const useUpdatePlayerAgentHistory = (): UseMutationResult<
    JSONResponse<DTO.PlayerAgentHistory>,
    Error,
    DTO.PlayerAgentHistory
> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedPlayerAgentHistory: DTO.PlayerAgentHistory) =>
            put<DTO.PlayerAgentHistory, DTO.PlayerAgentHistory>(
                `/player-agent-histories/${updatedPlayerAgentHistory.playerId}/${updatedPlayerAgentHistory.agentId}/${updatedPlayerAgentHistory.startDate}`,
                updatedPlayerAgentHistory
            ),
        {
            onSuccess: async () => queryClient.invalidateQueries(['playerAgentHistories']),
        }
    )
}

const getActivePlayerAgents = async (filters: PlayerAgentFilterParams): Promise<DTO.ActivePlayerAgents[]> =>
    (await get<DTO.ActivePlayerAgents[]>(`/player-agent-histories/active-player-agents`, filters)).data

export const useActivePlayerAgents = (
    filters: PlayerAgentFilterParams,
    enabled?: boolean
): UseQueryResult<DTO.ActivePlayerAgents[]> =>
    useQuery(['active-player-agents', filters], () => getActivePlayerAgents(filters), {
        enabled: enabled !== false,
    })
