import type { UseQueryResult, UseQueryOptions, QueryKey } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet } from '../api'

const usePlayerScoutingReport = (
    playerId: string | null | undefined,
    season: number,
    level: Enum.PlayerLevel | null | undefined,
    options?: Omit<
        UseQueryOptions<DTO.ScoutingReport, Error, DTO.ScoutingReport, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.ScoutingReport> =>
    useQuery<DTO.ScoutingReport, Error, DTO.ScoutingReport, QueryKey>(
        ['scouting-report', playerId, season, level],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.ScoutingReport>(`/profile/${playerId}/${season}/${level}/scouting-report`)).data,
        { ...options, enabled: !!playerId && !!season && options?.enabled !== false }
    )

export const serverGetPlayerScoutingReport = async (
    playerId: string | null | undefined,
    season: number,
    level: Enum.PlayerLevel | null | undefined,
    session: Session | null
): Promise<DTO.ScoutingReport> =>
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    (await serverGet<DTO.ScoutingReport>(`/profile/${playerId}/${season}/${level}/scouting-report`, session)).data

export default usePlayerScoutingReport
