import type { UseQueryOptions, UseQueryResult, QueryKey } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const usePlayerSalary = (
    playerId: string | null | undefined,
    season: number,
    options?: Omit<
        UseQueryOptions<DTO.PlayerContract | undefined, Error, DTO.PlayerContract | undefined, QueryKey>,
        'queryKey' | 'queryFn' | 'refetchInterval' | 'useErrorBoundary'
    >
): UseQueryResult<DTO.PlayerContract | undefined> =>
    useQuery<DTO.PlayerContract | undefined, Error, DTO.PlayerContract | undefined, QueryKey>(
        ['player-salary', playerId, season],
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        async () => (await get<DTO.PlayerContract | ''>(`/profile/${playerId}/${season}/salary`)).data || undefined,
        { ...options, enabled: !!playerId && !!season && options?.enabled !== false }
    )

export default usePlayerSalary
