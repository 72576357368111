import type { League } from '../definitions/enum/Games'
import type { ProjectionType } from '../definitions/enum/Standing'
import type { Constants } from '../definitions/dto/Constant'

type SelectedLeague = League | null | undefined

export type LeagueProjectionTypeUsage = 'draft' | 'standings'
export const getDefaultLeagueProjectionType = (
    league: SelectedLeague,
    usageType: LeagueProjectionTypeUsage
): ProjectionType => (league === 'NBA' && usageType === 'draft' ? 'TEAM_QUALITY_BLEND' : 'BLEND')

export const westchesterSlug = 'westchester-knicks'
export const knicksSlug = 'new-york-knicks'
export const getLeagueOrgTeamSlug = (league: SelectedLeague): 'westchester-knicks' | 'new-york-knicks' =>
    league === 'G-League' ? westchesterSlug : knicksSlug

export const getDraftYearConstantKey = (league: SelectedLeague): 'draftYear' | 'glgDraftYear' =>
    league === 'G-League' ? 'glgDraftYear' : 'draftYear'
export const getDraftYearConstant = (league: SelectedLeague, constants: Constants): number =>
    constants[getDraftYearConstantKey(league)]
