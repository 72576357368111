import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const useAgentPlayers = (playerId: string, season: number): UseQueryResult<DTO.AgentPlayers> =>
    useQuery(
        ['agent-players', playerId, season],
        async () => (await get<DTO.AgentPlayers>(`/profile/${playerId}/${season}/agent-players`)).data
    )

export default useAgentPlayers
