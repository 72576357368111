/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./API.d";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createDraftNightEvent = /* GraphQL */ `mutation CreateDraftNightEvent($input: CreateDraftNightEventInput!) {
  createDraftNightEvent(input: $input) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDraftNightEventMutationVariables,
  APITypes.CreateDraftNightEventMutation
>;
export const updateDraftNightEvent = /* GraphQL */ `mutation UpdateDraftNightEvent($input: UpdateDraftNightEventInput!) {
  updateDraftNightEvent(input: $input) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDraftNightEventMutationVariables,
  APITypes.UpdateDraftNightEventMutation
>;
export const deleteDraftNightEvent = /* GraphQL */ `mutation DeleteDraftNightEvent($input: DeleteDraftNightEventInput!) {
  deleteDraftNightEvent(input: $input) {
    draft
    name
    value
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDraftNightEventMutationVariables,
  APITypes.DeleteDraftNightEventMutation
>;
export const createDraftEntrant = /* GraphQL */ `mutation CreateDraftEntrant($input: CreateDraftEntrantInput!) {
  createDraftEntrant(input: $input) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDraftEntrantMutationVariables,
  APITypes.CreateDraftEntrantMutation
>;
export const updateDraftEntrant = /* GraphQL */ `mutation UpdateDraftEntrant($input: UpdateDraftEntrantInput!) {
  updateDraftEntrant(input: $input) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDraftEntrantMutationVariables,
  APITypes.UpdateDraftEntrantMutation
>;
export const deleteDraftEntrant = /* GraphQL */ `mutation DeleteDraftEntrant($input: DeleteDraftEntrantInput!) {
  deleteDraftEntrant(input: $input) {
    draft
    playerId
    fullName
    available
    pick
    teamName
    teamId
    position
    rank
    urlSlug
    ageOnDraftDay
    collegeClass
    teamAbbr
    teamUrlSlug
    teamLogo
    twoWay
    summerLeague
    draftAndStashGLG
    draftAndStashIntl
    draftOptionNotes
    greenRoomInvite
    contractType
    contractYears
    salaryAmount
    signingTeamId
    signingTeamLogo
    signingTeamAbbr
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDraftEntrantMutationVariables,
  APITypes.DeleteDraftEntrantMutation
>;
export const createPickOrder = /* GraphQL */ `mutation CreatePickOrder($input: CreatePickOrderInput!) {
  createPickOrder(input: $input) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePickOrderMutationVariables,
  APITypes.CreatePickOrderMutation
>;
export const updatePickOrder = /* GraphQL */ `mutation UpdatePickOrder($input: UpdatePickOrderInput!) {
  updatePickOrder(input: $input) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePickOrderMutationVariables,
  APITypes.UpdatePickOrderMutation
>;
export const deletePickOrder = /* GraphQL */ `mutation DeletePickOrder($input: DeletePickOrderInput!) {
  deletePickOrder(input: $input) {
    draft
    pick
    teamId
    teamName
    teamLogo
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePickOrderMutationVariables,
  APITypes.DeletePickOrderMutation
>;
