import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const useBoardColumns = (
    entityType: Enum.BoardEntityType,
    level: Enum.BoardLevels
): UseQueryResult<DTO.BoardColumn[]> =>
    useQuery(
        ['board-columns', entityType, level],
        async () => (await get<DTO.BoardColumn[]>(`/boards/columns/${entityType}`, { level })).data
    )

export default useBoardColumns
