import type { UseQueryOptions, UseQueryResult, QueryKey } from '@tanstack/react-query'
import type { Session } from 'next-auth'
import { useQuery } from '@tanstack/react-query'
import { get, serverGet, staticGet } from '../api'

export const queryKey = (playerId: string): QueryKey => ['players', playerId]
const apiPath = (playerId: string): string => `/players/${playerId}`

export const serverGetPlayer = async (playerSlug: string, session: Session | null): Promise<DTO.Player> =>
    (await serverGet<DTO.Player>(apiPath(playerSlug), session)).data

export const getPlayer = async (playerSlug: string): Promise<DTO.Player> =>
    (await get<DTO.Player>(apiPath(playerSlug))).data

export const getPlayerById = async (playerId: string): Promise<DTO.Player> =>
    (await get<DTO.Player>(`/players/by_id/${playerId}`)).data

// ONLY use in `getStaticPaths` or `getStaticProps` functions
export const staticGetPlayer = async (playerSlug: string): Promise<DTO.Player> =>
    (await staticGet<DTO.Player>(apiPath(playerSlug))).data

export const staticGetPlayerName = async (playerSlug: string): Promise<DTO.PlayerNames> =>
    (await staticGet<DTO.PlayerNames>(`/players/${playerSlug}/name`)).data

const usePlayer = (
    playerSlug: string | null | undefined,
    options?: Omit<UseQueryOptions<DTO.Player>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Player> =>
    // @ts-expect-error playerId will only get referenced here when it is defined (enabled)
    useQuery(queryKey(playerSlug), () => getPlayer(playerSlug, options), {
        ...options,
        enabled: !!playerSlug && options?.enabled !== false,
    })

export const useGetPlayerById = (
    playerId: string | null | undefined,
    options?: Omit<UseQueryOptions<DTO.Player>, 'queryKey' | 'queryFn'>
): UseQueryResult<DTO.Player> =>
    // @ts-expect-error playerId will only get referenced here when it is defined (enabled)
    useQuery(queryKey(playerId), () => getPlayerById(playerId), { enabled: !!playerId, ...options })

export const usePlayerIdLookupByPlayerId = (q: string): UseQueryResult<DTO.PlayerIdLookup[]> =>
    useQuery<DTO.PlayerIdLookup[]>(
        ['get-player-id-lookup', q],
        async () => (await get<DTO.PlayerIdLookup[]>('/players/player-id-lookup', { q })).data,
        {
            enabled: !!q && q.length >= 1,
        }
    )

export default usePlayer
