import type { UseQueryResult } from '@tanstack/react-query'
import { useQuery } from '@tanstack/react-query'
import { get } from '../api'

const apiPath = '/community-tags'

export const queryCommunityTags = async (): Promise<DTO.CommunityTag[]> => (await get<DTO.CommunityTag[]>(apiPath)).data

const useCommunityTags = (): UseQueryResult<DTO.CommunityTag[]> =>
    useQuery<DTO.CommunityTag[]>(['community-tags'], () => queryCommunityTags())

export default useCommunityTags
