'use client'

// https://react.dev/learn/you-might-not-need-an-effect#adjusting-some-state-when-a-prop-changes

import type { Primitive } from 'type-fest'
import { useState } from 'react'

type WatchVariableEffectFn<T> = (next: T) => unknown | Promise<unknown>
type WatchVariableCompareFn<T> = (prev: T, next: T) => boolean

export function defaultCompareFn<T>(prev: T, next: T): boolean {
    return Array.isArray(prev) && Array.isArray(next)
        ? prev.length === next.length && prev.every((v, i) => defaultCompareFn(v, next[i]))
        : prev === next
}

// do not require compareFn for primitive values
type OptionalCompareType = Primitive | Primitive[]
function useWatchVariable<T extends OptionalCompareType>(
    effect: WatchVariableEffectFn<T>,
    next: T,
    runOnInit?: boolean
): void
function useWatchVariable<T extends ReadonlyArray<OptionalCompareType>>(
    effect: WatchVariableEffectFn<T>,
    next: T,
    runOnInit?: boolean
): void
function useWatchVariable<T>(
    effect: WatchVariableEffectFn<T>,
    next: T,
    compareFn: WatchVariableCompareFn<T>,
    runOnInit?: boolean
): void

function useWatchVariable<T>(
    effect: WatchVariableEffectFn<T>,
    next: T,
    compareFnOrRunOnInit?: WatchVariableCompareFn<T> | boolean,
    runOnInit?: boolean
): void {
    const [prevVal, setPrevVal] = useState(() => {
        if (runOnInit === true || compareFnOrRunOnInit === true) effect(next)
        return next
    })
    const compare = typeof compareFnOrRunOnInit === 'function' ? compareFnOrRunOnInit : defaultCompareFn
    if (!compare(prevVal, next)) {
        setPrevVal(next)
        effect(next)
    }
}

export default useWatchVariable
