import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query'
import type { JSONResponse } from '@/lib/api'
import type { Session } from 'next-auth'
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query'
import { get, post, put, serverGet } from '../api'

type FeaturePermissionParams = {
    displayName: string
    status: string
}

type FeaturePermissionUpdateParams = FeaturePermissionParams & { slug: string }

type QueryKey = ['featurePermissions']

export const featurePermissionsQueryKey: QueryKey = ['featurePermissions']

const apiPath = '/feature-permissions'

export const serverQueryFeaturePermissions = async (session: Session | null): Promise<DTO.FeaturePermission> =>
    (await serverGet<DTO.FeaturePermission>(apiPath, session)).data

export const queryFeaturePermissions = async (): Promise<DTO.FeaturePermission> =>
    (await get<DTO.FeaturePermission>(apiPath)).data

export const useQueryFeaturePermissions = ({
    queryKey,
}: {
    queryKey: QueryKey
}): UseQueryResult<DTO.FeaturePermission[]> => useQuery(queryKey, () => queryFeaturePermissions())

export const useCreateFeaturePermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.FeaturePermission>, Error, FeaturePermissionParams> => {
    const queryClient = useQueryClient()
    return useMutation(
        (newFeaturePermission: FeaturePermissionParams) =>
            post<DTO.FeaturePermission, FeaturePermissionParams>(apiPath, newFeaturePermission),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}

export const useUpdateFeaturePermission = (
    queryKey: QueryKey
): UseMutationResult<JSONResponse<DTO.FeaturePermission>, Error, FeaturePermissionUpdateParams> => {
    const queryClient = useQueryClient()
    return useMutation(
        (updatedFeaturePermission: FeaturePermissionUpdateParams) =>
            put<DTO.FeaturePermission, FeaturePermissionUpdateParams>(
                `${apiPath}/${updatedFeaturePermission.slug}`,
                updatedFeaturePermission
            ),
        {
            onSuccess: () => queryClient.invalidateQueries(queryKey),
        }
    )
}
